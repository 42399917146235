import { Box, Button, TextField, Typography } from "@mui/material";
import ClientImg from "../../assets/images/images-svg/client-large.svg";
import React from "react";

const SessionUpdates = () => {
  return (
    <div className="sessionUpdates">
      <Box
        sx={{
          maxWidth: { xs: "100%", sm: "80%", md: "70%", lg: "50%" },
          margin: "10px 0px",
        }}
        position={"relative"}
      >
        <TextField fullWidth label="Leave Comment..." id="fullWidth" />
        <Button className="comment-btn" variant="contained">
          {" "}
          Leave Comment
        </Button>
      </Box>
      <Box className="comment-box">
        <Box className="comment">
          <Box className="comment-details">
            <Box className="flex">
              <span className="userMapCart__img">
                <img src={ClientImg} className="userSmallCircle" alt={""} />
              </span>
              <Typography className="userName">John Snow</Typography>
            </Box>
            <Typography className="comment-dtm"> Yesterday 10:09 AM</Typography>
          </Box>
          <Typography className="message">
            Updated the onboarding stage to{" "}
            <strong>credentialing Complete</strong>
          </Typography>
        </Box>
        <Box className="comment">
          <Box className="comment-details">
            <Box className="flex">
              <span className="userMapCart__img">
                <img src={ClientImg} className="userSmallCircle" alt={""} />
              </span>
              <Typography className="userName">John Snow</Typography>
            </Box>
            <Typography className="comment-dtm"> Yesterday 10:09 AM</Typography>
          </Box>
          <Typography className="message">
            Updated the onboarding stage to{" "}
            <strong>credentialing Complete</strong>
          </Typography>
        </Box>
        <Box className="comment">
          <Box className="comment-details">
            <Box className="flex">
              <span className="userMapCart__img">
                <img src={ClientImg} className="userSmallCircle" alt={""} />
              </span>
              <Typography className="userName">John Snow</Typography>
            </Box>
            <Typography className="comment-dtm"> Yesterday 10:09 AM</Typography>
          </Box>
          <Typography className="message">
            Updated the onboarding stage to{" "}
            <strong>credentialing Complete</strong>
          </Typography>
        </Box>
      </Box>
    </div>
  );
};

export default SessionUpdates;
