export const scheduleFilters = {
  dateSelect: null,
  cancelledChecked: false,
  rescheduleChecked: false,
  scheduleChecked: false,
  assessmentType: false,
  directTherpyType: false,
  parentAppointmentType: false,
  billablesType: false,
  nonBillableType: false,
};
