import React, { Component } from "react";
import { Tabs } from "../../constants/tabs";
import Message from "../../assets/images/images-png/Message.png";
import "./HeaderTab.scss"
interface HeaderTabProps {
  onTabClick: (tabData: Tabs) => void;
  selectedTab: string;
  tabs: Tabs[];
}

class HeaderTab extends Component<HeaderTabProps> {
  render(): React.ReactNode {
    return this.props.tabs.map((tab: Tabs, index: number) => {
      return (
        <div
          onClick={() => this.props.onTabClick(tab)}
          key={`${tab.tabName + index}`}
          className={
            this.props.selectedTab === tab.tabName
              ? "menuItem--active menuItem"
              : "menuItem"
          }
        >
          <span className="menuItem__link">
            {tab.tabName === "Messages" ? (
              <img src={Message} alt="Message icon" />
            ) : (
              tab.tabName
            )}{" "}
          </span>
        </div>
      );
    });
  }
}
export default HeaderTab;
