import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import MessageService from "../../../service/message.service";
import { initialState } from "../../../constants/message";
import { setResponseValue } from "../api-response";
import { TranslationData } from "../../../type/message";
import TranslationService from "../../../service/translation.service";

const messageSlice = createSlice({
  name: "messageSlice",
  initialState,
  reducers: {
    openMessage: (state, { payload }) => ({
      ...state,
      openedMessage: payload,
    }),
    setTranslation: (state, { payload }) => ({
      ...state,
      translatedMessage: payload,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(GetMessageByEntityId.fulfilled, (state, action) => {
      if (action.payload) {
        return {
          ...state,
          allMessageData: action.payload,
        };
      }
    });
    builder.addCase(PostCreateMessage.fulfilled, (state, { payload }) => {
      if (payload && payload.data) {
        const allMessageData = {
          ...state.allMessageData,
          allUserMessage: [
            payload.data.data,
            ...state.allMessageData.allUserMessage,
          ],
          [payload.messagetype]: state.allMessageData[payload.messagetype] + 1,
        };
        let openedMessage = null;
        if (payload.messagetype === "externalCount")
          openedMessage = payload.data.data;

        return {
          ...state,
          allMessageData,
          openedMessage,
        };
      }
      return state;
    });
    builder.addCase(UpdateMessageStatusById.fulfilled, (state, { payload }) => {
      if (payload) {
        return {
          ...state,
          allMessageData: {
            ...state.allMessageData,
            allUserMessage: state.allMessageData.allUserMessage.map((m) =>
              payload.id.includes(m.id)
                ? { ...m, seenUserId: [...m.seenUserId, payload.userId] }
                : m
            ),
            [payload.type]:
              state.allMessageData[payload.type] - payload.id.length,
          },
        };
      }
    });
    builder.addCase(PostSubComments.fulfilled, (state, { payload }) => {
      if (payload) {
        return {
          ...state,
          openedMessage: payload,
        };
      }
    });
    builder.addCase(getTranslationMessageId.fulfilled, (state, action) => {
      if (!action.payload) return state;
      const translatedMessage = action.payload.data as TranslationData;
      console.log("translatedMessage:",translatedMessage );
      if (!translatedMessage) return state;
      
      const { openedMessage } = state;
      if (action.payload.language !== translatedMessage.toLanguage)
        return { ...state, translatedMessage: openedMessage };
      if (!openedMessage) return state;
      return {
        ...state,
        translatedMessage: {
          ...openedMessage,
          message: translatedMessage.message,
          subject: translatedMessage.subject,
          comments: openedMessage.comments.map((comment, i) => ({
            ...comment,
            comment: translatedMessage.comment[i] || comment.comment,
          })),
        },
      };
    });
  },
});

//fetches the message by Id
export const GetMessageByEntityId = createAsyncThunk(
  `/message/getAll`,
  async ({ entityId }: { entityId: string }, { dispatch }) => {
    try {
      // dispatch(setResponseValue({ name: "pending", value: true }));
      const { data } = await MessageService.getMessageByEntityId(entityId);
      return data;
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(
        setResponseValue({ name: "message", value: e?.response?.data?.message })
      );
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

//update message Status by Id
export const UpdateMessageStatusById = createAsyncThunk(
  `/message/status`,
  async (
    data: {
      id: string[];
      type: "internalCount" | "externalCount";
      userId: string;
    },
    { dispatch }
  ) => {
    try {
      dispatch(setResponseValue({ name: "pending", value: true }));
      await MessageService.updateStatus(data.id);
      return data;
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(
        setResponseValue({
          name: "message",
          value: e?.response?.data?.message || "Error Occured",
        })
      );
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

//post create message
export const PostCreateMessage = createAsyncThunk(
  `/message/post`,
  async (
    {
      entityId,
      subject,
      message,
      whoCanView,
      type,
      from,
      entityType,
      messagetype,
      files,
    }: {
      entityId: string;
      subject: string;
      message: string;
      whoCanView: string[];
      type: string;
      from: string;
      entityType: string;
      messagetype: "internalCount" | "externalCount";
      files?: any; // Marking 'files' as optional
    },
    { dispatch }
  ) => {
    const payloadData = {
      subject: subject,
      message: message,
      whoCanView: whoCanView,
      type: type,
      from: from,
      entityType: entityType,
    };
    if (messagetype === "externalCount") {
      dispatch(setResponseValue({ name: "pending", value: true }));
    }
    try {
      const data = await MessageService.postCreateMessage(
        payloadData,
        entityId,
        files
      );
      if (data.status) {
        dispatch(setResponseValue({ name: "success", value: true }));
        dispatch(setResponseValue({ name: "message", value: data.message }));

        return { data, messagetype };
      }
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(
        setResponseValue({ name: "message", value: e?.response?.data?.message })
      );
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

//post sub comment message
export const PostSubComments = createAsyncThunk(
  `patchMessage`,
  async (
    {
      messageId,
      entityId,
      comment,
      entityType,
      files,
    }: {
      messageId: string;
      entityId: string;
      comment: string;
      entityType: string;
      files?: any;
    },
    { dispatch }
  ) => {
    try {
      const { data, status, message } = await MessageService.patchComments(
        messageId,
        entityId,
        comment,
        entityType,
        files
      );
      if (status) {
        dispatch(setResponseValue({ name: "success", value: true }));
        dispatch(setResponseValue({ name: "message", value: message }));
        return data;
      }
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(
        setResponseValue({ name: "message", value: e?.response?.data?.message })
      );
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

//fetches the message by Id
export const getTranslationMessageId = createAsyncThunk(
  `/message/getTranslationMessageId`,
  async (
    { language, messageId }: { messageId: string; language: string },
    { dispatch }
  ) => {
    try {
      const { data, status, message } = await TranslationService.getTranslation(
        "Message",  //source type
        messageId, 
        language
      );
      console.log("status, data:", status, data);
      
      if (status) {
        dispatch(setResponseValue({ name: "success", value: true }));
        dispatch(setResponseValue({ name: "message", value: message }));
        return { data: data.translationData, language };
      }
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(
        setResponseValue({ name: "message", value: e?.response?.data?.message })
      );
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

export const { openMessage, setTranslation } = messageSlice.actions;

export default messageSlice;
