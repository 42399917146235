import { Alert, Snackbar } from "@mui/material";
import React from "react";
import { ICloseModal } from "../../type/api-response";

interface Props {
  isOpen: boolean;
  isSuccess: boolean;
  message: string;
  closeModal: ICloseModal;
  handleClosePopup: (closeModal: ICloseModal) => void;
}

const ApiResponse: React.FC<Props> = ({
  isOpen,
  isSuccess,
  message,
  closeModal,
  handleClosePopup,
}) => {
  const handleClose = () => {
    setTimeout(() => handleClosePopup(closeModal), 5000);
  };
  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={isOpen}
        autoHideDuration={0}
        onClose={handleClose}
      >
        <Alert
          severity={isSuccess ? "success" : "error"}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default ApiResponse;
