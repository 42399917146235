import { useEffect } from "react";
import { useParams } from "react-router-dom";
import SurveyComponent from "./SurveyComponent";
import { useAppDispatch } from "../../redux/store";
import { GetAssessmentById } from "../../redux/features/providerForm/form-slice";

const OnBoardingForm = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (id && id !== "sessionForm") {
      dispatch(GetAssessmentById({ id }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  return <SurveyComponent formtype={id === "sessionForm" ? "Session Form" : "OnBoarding Form"}/>;
};

export default OnBoardingForm;
