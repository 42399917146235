import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { setResponseValue } from "../api-response";
import OrganizationDetails from "../../../service/org.service";
import { IOrganization } from "../../../type/organization";

const initialState: IOrganization = {
  orgData: {
    orgLogo: {
      containerName: "",
      fileName: "",
      key: "",
      url: "",
    },
  },
};

const organizationSlice = createSlice({
  name: "organizationSlice",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getOrgInfo.fulfilled, (state, action) => {
      if (action.payload) {
        return {
          ...state,
          orgData: {
            ...action.payload,
          },
        };
      }
      return state;
    });
  },
});

export const getOrgInfo = createAsyncThunk(
  "getOrgInfo",
  async (_, { getState, dispatch }) => {
    dispatch(setResponseValue({ name: "pending", value: true }));
    try {
      const { status, data } = await OrganizationDetails.getOrgInfo();
      if (status) {
        return data;
      }
    } catch (e: any) {
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

export default organizationSlice;