import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { AppDispatch, RootState, useAppSelector } from "../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  getTranslationMessageId,
  setTranslation,
} from "../../../redux/features/message/message-slice";
import { IMessage } from "../../../type/message";

interface MessageTypeProps {
  selectedCommunicationTab: string;
  setSelectedCommunicationTab: React.Dispatch<React.SetStateAction<string>>;
  setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
}

const MessageType: React.FC<MessageTypeProps> = ({
  selectedCommunicationTab,
  setSelectedCommunicationTab,
  setOpenDialog,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const [language, setLanguage] = useState("");
  const [loading, setLoading] = useState(false);
  const languageMap: Record<"en" | "es" | "zh-cn", string> = {
    en: "English",
    es: "Spanish",
    "zh-cn": "Chinese",
  };
  const { internalCount, externalCount } = useAppSelector(
    (state) => state.messageSlice.allMessageData
  );

  const { openedMessage, translatedMessage } = useSelector<RootState, IMessage>(
    (state) => state.messageSlice
  );

  const translateText = async (lang: string) => {
    try {
      setLoading(true);
      if (!lang) {
        setLanguage("");
        return dispatch(setTranslation(null));
      }
      if (openedMessage) {
        await dispatch(
          getTranslationMessageId({
            messageId: openedMessage?.id,
            language: lang,
          })
        );
      } else {
        dispatch(setTranslation(null));
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const handleChangeLanguage = (lang: string) => {
    setLanguage(lang);
    translateText(lang);
  };

  useEffect(() => {
    setLanguage("");
  }, [openedMessage?.id]);
  return (
    <Grid container spacing={3} columns={16}>
      <Grid item xs={16} md={16}>
        <div className="top-btn-list">
          <div>
            {/* Turn off Internal Notice by Hui 11.6.2024 */}
            {/* <Box
              className={
                selectedCommunicationTab === "Internal" ? "active" : ""
              }
              onClick={() => {
                setSelectedCommunicationTab("Internal");
              }}
            >
              {internalCount > 0 && (
                <span
                  style={{
                    height: "10px",
                    width: "10px",
                    borderRadius: "20px",
                    backgroundColor: "#096dd9",
                    content: "",
                    position: "absolute",
                    left: "7px",
                    top: "14px",
                  }}
                ></span>
              )}
              <Typography>Internal Notice</Typography>
              <strong>{internalCount}</strong>
            </Box> */}
            <Box
              className={
                selectedCommunicationTab === "External" ? "active" : ""
              }
              onClick={() => {
                setSelectedCommunicationTab("External");
              }}
            >
              {externalCount > 0 ? (
                <span
                  style={{
                    height: "10px",
                    width: "10px",
                    borderRadius: "20px",
                    backgroundColor: "#096dd9",
                    content: "",
                    position: "absolute",
                    left: "7px",
                    top: "14px",
                  }}
                ></span>
              ) : (
                ""
              )}
              <Typography>In-Mail</Typography>
              <strong>{externalCount}</strong>
            </Box>
            <Box
              className={
                selectedCommunicationTab === "SMS"
                  ? "active sms-btn"
                  : "sms-btn"
              }
              onClick={() => {
                setSelectedCommunicationTab("SMS");
              }}
            >
              SMS
              <strong>0</strong>
            </Box>

            {openedMessage && (
              <FormControl
                size="small"
                className="translate-btn"
              >
                <Select
                  className="demo-simple-select"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  displayEmpty={true}
                  placeholder="Select Language"
                  value={language}
                  renderValue={(value) => {
                    if (loading) {
                      return <CircularProgress size={20} />;
                    } else {
                      return (
                        languageMap[value as "en" | "es" | "zh-cn"] ||
                        "Translate"
                      );
                    }
                  }}
                  // onChange={({ target: { value } }) => {
                  //   translateText(value);
                  //   setLanguage(value);
                  // }}
                  onChange={(e: any) => {
                    handleChangeLanguage(e.target.value);
                  }}
                  sx={{
                    boxShadow: "none",
                    ".MuiOutlinedInput-notchedOutline": { border: 0 },
                    "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                      {
                        border: 0,
                      },
                    "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                      {
                        border: 0,
                      },
                  }}
                >
                  <MenuItem
                    value={"en"}
                    key={"en"}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    English
                  </MenuItem>
                  <MenuItem
                    value={"es"}
                    key={"es"}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    Spanish
                  </MenuItem>
                  <MenuItem
                    value={"zh-cn"}
                    key={"zh-cn"}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    Chinese
                  </MenuItem>
                </Select>
              </FormControl>
            )}
            {translatedMessage && (
              <Box onClick={() => translateText("")}>
                <Typography>
                  <strong>See original</strong>
                </Typography>
              </Box>
            )}
          </div>
          {selectedCommunicationTab === "External" && (
            <Button
              className="btn-right"
              style={{
                textTransform: "unset",
                backgroundColor: "rgb(9, 109, 217)",
                color: "white",
              }}
              onClick={() => setOpenDialog(true)}
            >
              Compose
            </Button>
          )}
        </div>
      </Grid>
    </Grid>
  );
};

export default MessageType;
