export interface ITask {
  id: string;
  name: string;
  description: string;
  type: string;
  priority: string;
  status: string;
  checklist: checklist[];
  dueDate: Date;
  entityId: string;
  entityType: string;
  assignedTo: {
    id: string;
    firstName: string;
    lastName: string;
    profilePictureUrl: string;
  };
  updatedAt: string;
  createdAt: string;
  createdBy: string;
  updatedBy: string;
}

export interface ITaskType {
  id: string;
  conceptValue: string;
  data: null | string;
}

export interface checklist {
  value: string;
  checked: boolean;
}

export enum Priority {
  Low = "Low",
  Medium = "Medium",
  High = "High",
}
export enum Status {
  NotStarted = "Not Started",
  InProgress = "In Progress",
  Cancelled = "Cancelled",
  Done = "Done",
}
export const PriorityType = ["All", "Low", "High", "Medium"];
export const StatusType = [
  "All",
  "Not Started",
  "In Progress",
  "Cancelled",
  "Done",
];
export const TimeType = ["All", "Current Month", "Last 3 Months", "Last Year"];
export const FilterData = [
  { label: "DateRange", data: TimeType },
  { label: "Priority", data: PriorityType },
  { label: "Status", data: StatusType },
];
