import { IOnboarding, IOnboardingForm, IOnboardingtData } from "../type/form";

export const Onboarding: IOnboarding = {
  id: "",
  form: {
    formCategory: "",
    createdAt: "",
    updatedAt: "",
  },
};

export const Onboardingform: IOnboardingForm = {
  authCode: null,
  authId: null,
  cancelDtm: null,
  createdAt: "",
  createdBy: "",
  entityId: "",
  entityType: "",
  errorDtm: null,
  errorMsg: null,
  evaluateDtm: null,
  evaluation: null,
  form: {
    id: "",
    formCategory: "",
    formType: "",
    embeddedCode: null,
    formSchema: {
      title: "",
      logoPosition: "",
      showProgressBar: "",
      completedHtmlOnCondition: [{}],
      pages: [
        {
          name: "",
          elements: [],
        },
      ],
      triggers: [
        {
          type: "",
          expression: "",
          gotoName: "",
        },
      ],
    },
    frontegg_tenantId: "",
    active: true,
    createdAt: "",
    updatedAt: "",
    isComplete: false,
    isJot: false,
    name: null,
    url: null,
  },
  formData: null,
  id: "",
  mapDtm: null,
  resultData: null,
  saveDtm: null,
  sentDtm: "",
  startDtm: null,
  status: "",
  submitDtm: null,
  updatedAt: "",
};

export const OnboardingFormData: IOnboardingtData = {
  onBoardingList: [Onboarding],
  onBoardingform: Onboardingform,
};

export const ITEM_HEIGHT = 48;
export const ITEM_PADDING_TOP = 8;
export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};