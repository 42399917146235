import { IAvailabilityDetails, ISetavailability, Time } from "../type/availability";
import moment from "moment";

//AvailabilityDetailsForm constant
export const IAvailabilityTimes: Time[] = [
  { key: 0, value: "06:00 AM" },
  { key: 1, value: "06:15 AM" },
  { key: 2, value: "06:30 AM" },
  { key: 3, value: "06:45 AM" },
  { key: 4, value: "07:00 AM" },
  { key: 5, value: "07:15 AM" },
  { key: 6, value: "07:30 AM" },
  { key: 7, value: "07:45 AM" },
  { key: 8, value: "08:00 AM" },
  { key: 9, value: "08:15 AM" },
  { key: 10, value: "08:30 AM" },
  { key: 11, value: "08:45 AM" },
  { key: 12, value: "09:00 AM" },
  { key: 13, value: "09:15 AM" },
  { key: 14, value: "09:30 AM" },
  { key: 15, value: "09:45 AM" },
  { key: 16, value: "10:00 AM" },
  { key: 17, value: "10:15 AM" },
  { key: 18, value: "10:30 AM" },
  { key: 19, value: "10:45 AM" },
  { key: 20, value: "11:00 AM" },
  { key: 21, value: "11:15 AM" },
  { key: 22, value: "11:30 AM" },
  { key: 23, value: "11:45 AM" },
  { key: 24, value: "12:00 PM" },
  { key: 25, value: "12:15 PM" },
  { key: 26, value: "12:30 PM" },
  { key: 27, value: "12:45 PM" },
  { key: 28, value: "01:00 PM" },
  { key: 29, value: "01:15 PM" },
  { key: 30, value: "01:30 PM" },
  { key: 31, value: "01:45 PM" },
  { key: 32, value: "02:00 PM" },
  { key: 33, value: "02:15 PM" },
  { key: 34, value: "02:30 PM" },
  { key: 35, value: "02:45 PM" },
  { key: 36, value: "03:00 PM" },
  { key: 37, value: "03:15 PM" },
  { key: 38, value: "03:30 PM" },
  { key: 39, value: "03:45 PM" },
  { key: 40, value: "04:00 PM" },
  { key: 41, value: "04:15 PM" },
  { key: 42, value: "04:30 PM" },
  { key: 43, value: "04:45 PM" },
  { key: 44, value: "05:00 PM" },
  { key: 45, value: "05:15 PM" },
  { key: 46, value: "05:30 PM" },
  { key: 47, value: "05:45 PM" },
  { key: 48, value: "06:00 PM" },
  { key: 49, value: "06:15 PM" },
  { key: 50, value: "06:30 PM" },
  { key: 51, value: "06:45 PM" },
  { key: 52, value: "07:00 PM" },
  { key: 53, value: "07:15 PM" },
  { key: 54, value: "07:30 PM" },
  { key: 55, value: "07:45 PM" },
  { key: 56, value: "08:00 PM" },
  { key: 57, value: "08:15 PM" },
  { key: 58, value: "08:30 PM" },
  { key: 59, value: "08:45 PM" },
  { key: 60, value: "09:00 PM" },
  { key: 61, value: "09:15 PM" },
  { key: 62, value: "09:30 PM" },
  { key: 63, value: "09:45 PM" },
  { key: 64, value: "10:00 PM" },
  { key: 65, value: "10:15 PM" },
  { key: 66, value: "10:30 PM" },
  { key: 67, value: "10:45 PM" },
  { key: 68, value: "11:00 PM" },
  { key: 69, value: "11:15 PM" },
  { key: 70, value: "11:30 PM" },
  { key: 71, value: "11:45 PM" },
  { key: 72, value: "12:00 AM" },
];

//WeekDays
export const IWeekDays = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export const IAvailabilityTimesForSchedulesBars: string[] = [
  "06:00 AM",
  "06:15 AM",
  "06:30 AM",
  "06:45 AM",
  "07:00 AM",
  "07:15 AM",
  "07:30 AM",
  "07:45 AM",
  "08:00 AM",
  "08:15 AM",
  "08:30 AM",
  "08:45 AM",
  "09:00 AM",
  "09:15 AM",
  "09:30 AM",
  "09:45 AM",
  "10:00 AM",
  "10:15 AM",
  "10:30 AM",
  "10:45 AM",
  "11:00 AM",
  "11:15 AM",
  "11:30 AM",
  "11:45 AM",
  "12:00 PM",
  "12:15 PM",
  "12:30 PM",
  "12:45 PM",
  "01:00 PM",
  "01:15 PM",
  "01:30 PM",
  "01:45 PM",
  "02:00 PM",
  "02:15 PM",
  "02:30 PM",
  "02:45 PM",
  "03:00 PM",
  "03:15 PM",
  "03:30 PM",
  "03:45 PM",
  "04:00 PM",
  "04:15 PM",
  "04:30 PM",
  "04:45 PM",
  "05:00 PM",
  "05:15 PM",
  "05:30 PM",
  "05:45 PM",
  "06:00 PM",
  "06:15 PM",
  "06:30 PM",
  "06:45 PM",
  "07:00 PM",
  "07:15 PM",
  "07:30 PM",
  "07:45 PM",
  "08:00 PM",
  "08:15 PM",
  "08:30 PM",
  "08:45 PM",
  "09:00 PM",
  "09:15 PM",
  "09:30 PM",
  "09:45 PM",
  "10:00 PM",
  "10:15 PM",
  "10:30 PM",
  "10:45 PM",
  "11:00 PM",
  "11:15 PM",
  "11:30 PM",
  "11:45 PM",
  "12:00 AM",
];

export const defaultProviderAvailabilityDetail: IAvailabilityDetails = {
  id: "",
  entityType: "Provider",
  entityId: null,
  beginDate: moment().local().format("YYYY-MM-DD"),
  endDate: moment().local().add(6, "M").local().format("YYYY-MM-DD"),
  createdAt: "",
  updatedAt: "",
  selectedDays: [
    {
      dayOfWeek: "",
      startTime: "",
      endTime: "",
    },
  ],
  heatMap: [],
  timeSlots: [],
  availabilitySelectedDays: [],
  availabilityName: "",
};

export const availability: ISetavailability = {
  availability: {
    ...defaultProviderAvailabilityDetail,
  },
};
