import axios from "../axios/axios";

//fetch assessment forms
export const getAssessmentForm = async (formType: string) => {
  try {
    const { data } = await axios.get(`/form?formType=${formType}`);
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

//get all Onboarding forms by Id
export const getOnboardingById = async (id: string) => {
  try {
    const { data } = await axios.get(`/formrequest/${id}`);

    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

//update form result
export const updateFormresult = async (id: string, res: any) => {
  try {
    const { data } = await axios.put(`/formrequest/${id}`, res);
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

const formService = {
  getAssessmentForm,
  getOnboardingById,
  updateFormresult,
};

export default formService;
