import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import providerService from "../../service/provider.service";
import { setResponseValue } from "./api-response";
import { IEmployeePortalData } from "../../type/providerDetails";
import { IClientListDetails } from "../../type/client-info";
import ClientService from "../../service/client.service";
import MessagesSlice from "../../service/message.service";
import { ICurrentMessage } from "../../type/message";

export interface IListData {
  clientList: IClientListDetails[];
  providerList: IEmployeePortalData[];
  currentMessage: ICurrentMessage[];
}

const initialState: IListData = {
  clientList: [],
  providerList: [],
  currentMessage: [],
};

const ListDataSlice = createSlice({
  name: "providerSlice",
  initialState: initialState,
  reducers: {
    setProviderMessageCount: (state, action) => {
      const updatedProviderAllList = state.providerList.map(
        (ele: IEmployeePortalData) => {
          if (ele?.employmentDetails.id === action.payload) {
            return {
              ...ele,
              employmentDetails: {
                ...ele.employmentDetails,
                messageCount: ele.employmentDetails.messageCount - 1,
              },
            };
          }
          return ele;
        }
      );
      return {
        ...state,
        providerList: updatedProviderAllList,
      };
    },
    setClientMessageCount: (state, action) => {
      const updatedClientlist = state.clientList.map(
        (ele: IClientListDetails) => {
          if (ele.clientBasicDetails.id === action.payload) {
            return {
              ...ele,
              clientBasicDetails: {
                ...ele.clientBasicDetails,
                messageCount: ele.clientBasicDetails.messageCount - 1,
              },
            };
          }
          return ele;
        }
      );
      return {
        ...state,
        clientList: updatedClientlist,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getProvidersByEmployee.fulfilled,
      (state, action: PayloadAction<IEmployeePortalData[]>) => {
        if (action.payload !== undefined) {
          state.providerList = action.payload;
        }
      }
    );
    builder.addCase(
      getClientsByEmployee.fulfilled,
      (state, action: PayloadAction<IClientListDetails[]>) => {
        if (action.payload !== undefined) {
          state.clientList = action.payload;
        }
      }
    );
    // builder.addCase(GetCurrentMessage.fulfilled, (state, action) => {
    //   if (action.payload !== undefined) {
    //     state.currentMessage = action.payload;
    //   }
    // });
  },
});

export const getProvidersByEmployee = createAsyncThunk(
  `/getProvidersByEmployee`,
  async (_, { getState, dispatch }) => {
    try {
      const { data, status } = await providerService.getProvidersByEmployee();

      if (status) {
        return data.data;
      }
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(
        setResponseValue({
          name: "message",
          value: e.message,
        })
      );
    }
  }
);

export const getClientsByEmployee = createAsyncThunk(
  `/getClientsByEmployee`,
  async (_, { getState, dispatch }) => {
    try {
      const { data, status } = await ClientService.getClientsByEmployee();
      if (status) {
        return data.data;
      }
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(
        setResponseValue({
          name: "message",
          value: e.message,
        })
      );
    }
  }
);

//Deprecated this!!
// export const GetCurrentMessage = createAsyncThunk(
//   `/employee/activeMessage`,
//   async (_, { getState, dispatch }) => {
//     try {
//       const { data, status } = await MessagesSlice.getCurrentMessage();
//       if (status) {
//         dispatch(setResponseValue({ name: "success", value: true }));
//         return data;
//       }
//     } catch (e: any) {
//       dispatch(setResponseValue({ name: "error", value: true }));
//       dispatch(
//         setResponseValue({
//           name: "message",
//           value: e.message,
//         })
//       );
//     }
//   }
// );

export const { setProviderMessageCount, setClientMessageCount } =
  ListDataSlice.actions;
export default ListDataSlice;
