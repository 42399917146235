import ProviderImg from "../../../assets/images/images-svg/provider-large-vertical.svg";
import { useEffect } from "react";
import { useAppSelector } from "../../../redux/store";
import { ReactComponent as UserVerified } from "../../../assets/images/images-svg/verified.svg";
import { UserListProps } from "./UserList";

const EmployeeList: React.FC<UserListProps> = ({
  selectedUser,
  setSelectedUser,
  isMobileView,
}) => {
  const providerList = useAppSelector(
    (state) => state.listDataSlice.providerList
  );

  useEffect(() => {
    if (providerList[0] && !isMobileView) {
      setSelectedUser({
        id: providerList[0]?.employmentDetails?.id,
        name: `${providerList[0].employmentDetails.firstName} ${providerList[0].employmentDetails.lastName}`,
        type: "Provider",
      });
    }
  }, [providerList.length, isMobileView]);

  return (
    <div className="mapUserList">
      {providerList.map((item) => {
        return (
          <div
            className={
              selectedUser?.id === item?.employmentDetails.id
                ? "fromGroup-chip-active"
                : "userMapCart"
            }
            key={item?.employmentDetails.id}
            onClick={() =>
              setSelectedUser({
                id: item?.employmentDetails?.id,
                name: `${item.employmentDetails.firstName} ${item.employmentDetails.lastName}`,
                type: "Provider",
              })
            }
          >
            <div className="cardLevelBlock">
              <table>
                <thead>
                  <tr>
                    <th
                      style={{
                        display: "flex",
                        gap: "10px",
                        justifyContent: "space-between",
                        marginRight: "16px",
                        alignItems: "center",
                      }}
                    >
                      {item.employmentDetails.messageCount > 0 && (
                        <>
                          <span
                            style={{
                              width: "10px",
                              borderRadius: "50%",
                              background: "#096dd9",
                              padding: "5px",
                            }}
                          ></span>
                        </>
                      )}
                      <span className="userMapCart__img">
                        <img
                          src={
                            item?.employmentDetails?.providerProfile?.url ||
                            ProviderImg
                          }
                          className="userSmallCircle"
                          alt=""
                        />
                        {item?.employmentDetails?.isSupervisor && (
                          <span className="verifiedIcon">
                            <UserVerified />
                          </span>
                        )}
                      </span>
                    </th>
                    <th>
                      <div className="userMapCart__name">
                        {item?.employmentDetails?.firstName}
                        {item?.employmentDetails?.lastName}
                      </div>
                    </th>
                  </tr>
                </thead>
              </table>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default EmployeeList;
