import { useEffect, useRef } from "react";
import { Card } from "@mui/material";
import SplitterLayout from "react-splitter-layout";

import MyTasks from "./MyTasks";

import FocusImg from "../../assets/images/images-svg/projectIcon.svg";

import "react-splitter-layout/lib/index.css";
import "./task.scss";

const Task = () => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const secondaryInitialPercentage = 80;

  useEffect(() => {
    const calculateSecondaryInitialSize = () => {
      if (containerRef.current) {
        const containerWidth = containerRef.current.offsetWidth;
        const secondaryInitialSizePixels =
          (containerWidth * secondaryInitialPercentage) / 100;

        return secondaryInitialSizePixels;
      }
      return undefined;
    };
    const secondaryInitialSize = calculateSecondaryInitialSize();
    if (secondaryInitialSize !== undefined) {
      initial = secondaryInitialSize;
    }
  }, []);

  let initial: number = (window.innerWidth * 85) / 100;
  let priWidth: number = (window.innerWidth * 0.5) / 100;
  let secWidth: number = (window.innerWidth * 77) / 100;

  const setSplitterData = (value: any) => {
    initial = (value * 85) / 100;
    priWidth = (value * 0.5) / 100;
    secWidth = (value * 77) / 100;
  };
  useEffect(() => {
    window.addEventListener(
      "resize",
      function (event: any) {
        setSplitterData(event.target?.window.innerWidth);
      },
      true
    );
  }, []);
  //#endregi
  return (
    <div
      className="mapWrapper"
      style={{ display: "flex", height: "100%" }}
      ref={containerRef}
    >
      <SplitterLayout
        customClassName="splitter-layout"
        primaryMinSize={priWidth}
        secondaryMinSize={secWidth}
      >
        <Card className="sidePanel-card">
          <div className="task-sidePanel">
            <img src={FocusImg} alt={"Focus"} />
            Focus
          </div>
          <div className="panel-child activeBtn">My Tasks</div>
        </Card>
        <MyTasks />
      </SplitterLayout>
    </div>
  );
};

export default Task;
