export interface IScheduleFilters {
  dateSelect: null,
  cancelledChecked: boolean,
  rescheduleChecked: boolean,
  scheduleChecked: boolean,
  assessmentType: boolean,
  directTherpyType: boolean,
  parentAppointmentType: boolean,
  billablesType: boolean,
  nonBillableType: boolean,
};

export interface  IEmployeeScheduleSettings {
  allowEmployeeToSeeAvailablity: boolean,
        allowEmployeeToUpdateAvailablity: boolean
}

export enum ServiceColor {
  Assessment = "Assessment",
  DirectTherapy = "Direct Therapy",
  ParentTraining = "Parent Training",
  ProtocolModification = "Protocol modification",
}

// convert Time format 12 to 24
export const convertTime12to24 = (time12h: any) => {
  const [time, modifier] = time12h?.split(" ");

  let [hours, minutes] = time?.split(":");

  if (hours === "12") {
    hours = "00";
  }

  if (modifier === "PM") {
    hours = parseInt(hours, 10) + 12;
  }

  return `${hours}:${minutes}:00`;
};