import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "@descope/react-sdk";
import { registerLicense } from "@syncfusion/ej2-base";
import GlobalApiResponse from "./layouts/global-api-response/GlobalApiResponse";

// const contextOptions = {
//   baseUrl: "https://app-0vfc08hnea3p.frontegg.com",
//   clientId: "fb852e27-0b1a-4485-8137-68aadb5bccfa",
// };

// const authOptions = {
//   // keepSessionAlive: true // Uncomment this in order to maintain the session alive
// };

// Registering Syncfusion license key
registerLicense(
 process.env.REACT_APP_SYNCFUSION_LICENSE_KEY||""
);

// For react 18:
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const descopeProjectId = process.env.REACT_APP_DESCOPE_PROJECT_ID || "";
const cookieDomain = process.env.REACT_APP_COOKIE_DOMAIN || "";

// root.render(
root.render(
  <Provider store={store}>
    <GlobalApiResponse />
    <BrowserRouter>
      <AuthProvider projectId={descopeProjectId} baseUrl={cookieDomain}>
        <App />
      </AuthProvider>
    </BrowserRouter>
  </Provider>
);
