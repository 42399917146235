import React, { useEffect, useState } from "react";
import { Close, Delete } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DropResult,
  Droppable,
} from "react-beautiful-dnd";

import { useAppDispatch, useAppSelector } from "../../redux/store";
import {
  UpdateTaskbyId,
  GetAllTaskType,
} from "../../redux/features/taskManager/taskManagerSlice";

import { ITask, Priority, Status, checklist } from "../../type/taskManager";
import { MenuProps } from "../../constants/form";

import "./taskmodal.scss";

interface ITaskData {
  name: string;
  description: string;
  type: string;
  priority: string;
  status: string;
  dueDate: Date;
  checklist: checklist[];
}

const Checklist: React.FC<{
  item: checklist;
  updateChecklist: () => void;
  deleteCheckList: () => void;
  provider: DraggableProvided;
}> = ({ item, updateChecklist, deleteCheckList, provider }) => {
  return (
    <Box
      ref={provider.innerRef}
      {...provider.dragHandleProps}
      {...provider.draggableProps}
      className="checklist"
    >
      <FormControlLabel
        control={<Checkbox checked={item.checked} onChange={updateChecklist} />}
        label={item.value}
      />
      <Delete
        onClick={deleteCheckList}
        sx={{ cursor: "pointer" }}
        style={{ color: "#096DD9" }}
      />
    </Box>
  );
};

const AddTaskModal: React.FC<{
  setAddTaskModal: React.Dispatch<React.SetStateAction<boolean>>;
  currentTask: ITask;
}> = ({ setAddTaskModal, currentTask }) => {
  //dispatch
  const dispatch = useAppDispatch();

  const taskTypes = useAppSelector((state) => state.taskManagerSlice.taskTypes);
  //stores feilds for task
  const [task, setTask] = useState<ITaskData>({
    name: currentTask.name,
    description: currentTask.description,
    type: currentTask.type,
    priority: currentTask.priority,
    checklist: currentTask.checklist,
    status: currentTask.status,
    dueDate: currentTask?.dueDate,
  });

  //stores single checklist to be added
  const [list, setlist] = useState<checklist>({
    checked: false,
    value: "",
  });

  //handles errors
  const [hasError, setHasError] = useState<boolean>(false);

  //handle add checklist
  const AddChecklist = () => {
    if (list.value !== "") {
      setTask({ ...task, checklist: [...task.checklist, list] });
      setlist({
        checked: false,
        value: "",
      });
    }
  };

  //handles update checklist
  const updateChecklist = (checklistName: string, index: number) => {
    const updatedChecklist = task.checklist.map((item, i) => {
      if (i === index && checklistName === item.value) {
        return { ...item, checked: !item.checked };
      } else {
        return item;
      }
    });
    // Set the updated checklist in the task state
    setTask({ ...task, checklist: updatedChecklist });
  };

  const deleteCheckList = (index: number) => {
    setTask((pre) => ({
      ...pre,
      checklist: pre.checklist.filter((_, i) => i !== index),
    }));
  };

  const reorderCheckList = ({ destination, source }: DropResult) => {
    if (!destination) return;
    if (source.index === destination.index) return;
    const draggedItem = task.checklist[source.index];
    const newCheckList = task.checklist.filter((_, i) => i !== source.index);
    newCheckList.splice(destination.index, 0, draggedItem);
    setTask((pre) => ({ ...pre, checklist: newCheckList }));
  };

  const handleSelectDueDate = (value: Date | null) => {
    if (value !== null) {
      setTask({ ...task, dueDate: value });
    }
  };

  //handle post and update task
  const handleSubmit = () => {
    if (task.name !== "") {
      dispatch(
        UpdateTaskbyId({
          id: currentTask.id,
          name: task.name,
          description: task.description,
          type: task.type,
          priority: task.priority,
          status: task.status,
          checklist: task.checklist,
          dueDate: task.dueDate,
        })
      );

      setAddTaskModal(false);
    } else {
      setHasError(true);
      return;
    }
  };

  return (
    <div>
      <Box className="largeModel__head">
        <Typography variant="h5" className="largeModel__title">
          Update Task
        </Typography>
        <Button
          className="largeModel__closeBtn"
          onClick={() => setAddTaskModal(false)}
        >
          <Close />
        </Button>
      </Box>
      <div className="create-new-board">
        <Grid p={4} item xs={12} container spacing={2}>
          <Grid item xs={12}>
            <Box className="fromGroup">
              <InputLabel className="fromLabel" variant="standard">
                Task Name
              </InputLabel>
              <FormControl>
                <TextField
                  value={task?.name}
                  onChange={({ target: { value } }) =>
                    setTask({ ...task, name: value })
                  }
                />
              </FormControl>
              {task.name === "" && hasError && (
                <FormHelperText style={{ color: "#d32f2f" }}>
                  Please enter name
                </FormHelperText>
              )}
            </Box>
          </Grid>
          <Grid item xs={4} md={6}>
            <Box className="fromGroup">
              <InputLabel id="demo-simple-select-label" className="fromLabel">
                Type
              </InputLabel>
              <FormControl>
                <Select
                  className="form-control"
                  value={task?.type}
                  MenuProps={MenuProps}
                  onChange={({ target: { value } }) =>
                    setTask({ ...task, type: value })
                  }
                >
                  {taskTypes.map((type, i) => (
                    <MenuItem key={`${type.id}-${i}`} value={type.conceptValue}>
                      {type.conceptValue}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={2} md={6}>
            <Box className="fromGroup">
              <InputLabel id="demo-simple-select-label" className="fromLabel">
                Due Date
              </InputLabel>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  className="singleDatePicker"
                  value={task.dueDate}
                  onChange={(e) => {
                    handleSelectDueDate(e);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </Box>
          </Grid>
          <Grid item xs={4} md={6}>
            <Box className="fromGroup">
              <InputLabel className="fromLabel" variant="standard">
                Status
              </InputLabel>
              <div className="fromGroup-chips">
                {Object.values(Status).map((item: string, index: number) => (
                  <Card
                    className={
                      task?.status === item ? "fromGroup-chip-active" : ""
                    }
                    onClick={(
                      e: React.MouseEvent<HTMLDivElement, MouseEvent>
                    ) => setTask({ ...task, status: item })}
                    key={index}
                  >
                    {item}
                  </Card>
                ))}
              </div>
            </Box>
          </Grid>
          <Grid item xs={4} md={6}>
            <Box className="fromGroup">
              <InputLabel id="demo-simple-select-label" className="fromLabel">
                Priority
              </InputLabel>
              <div className="fromGroup-chips">
                {Object.keys(Priority).map((item: string, index: number) => (
                  <Card
                    className={
                      task?.priority === item ? "fromGroup-chip-active" : ""
                    }
                    onClick={(
                      e: React.MouseEvent<HTMLDivElement, MouseEvent>
                    ) => setTask({ ...task, priority: item })}
                    key={index}
                  >
                    {item}
                  </Card>
                ))}
              </div>
              {task.priority === "" && hasError && (
                <FormHelperText style={{ color: "#d32f2f" }}>
                  Please select atleast one priority
                </FormHelperText>
              )}
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box className="fromGroup">
              <InputLabel className="fromLabel">Description</InputLabel>
              <FormControl>
                <TextField
                  value={task?.description}
                  onChange={({ target: { value } }) =>
                    setTask({ ...task, description: value })
                  }
                  rows={2}
                  multiline
                />
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box className="fromGroup">
              <InputLabel className="fromLabel">Checklist</InputLabel>
              <FormControl sx={{ position: "relative" }}>
                <TextField
                  value={list.value}
                  onChange={(e) =>
                    setlist({ value: e.target.value, checked: false })
                  }
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      AddChecklist();
                    }
                  }}
                />
                <Button
                  sx={{
                    position: "absolute",
                    right: "4px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    height: "30px",
                  }}
                  onClick={() => AddChecklist()}
                >
                  Add
                </Button>
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={11.3}>
            <DragDropContext onDragEnd={reorderCheckList}>
              <Droppable
                droppableId="droppableId"
                direction="vertical"
                children={(provider) => (
                  <Box
                    minHeight={task.checklist.length * 50}
                    ref={provider.innerRef}
                    {...provider.droppableProps}
                  >
                    {task.checklist.map((item, index) => (
                      <Draggable
                        index={index}
                        key={index}
                        draggableId={index.toString()}
                        children={(provider) => (
                          <Checklist
                            provider={provider}
                            item={item}
                            deleteCheckList={deleteCheckList.bind(null, index)}
                            updateChecklist={updateChecklist.bind(
                              null,
                              item.value,
                              index
                            )}
                          />
                        )}
                      />
                    ))}
                  </Box>
                )}
              />
            </DragDropContext>
          </Grid>
        </Grid>
        <Box className="create-new-board-footer">
          <Button onClick={() => handleSubmit()}>Update Task</Button>
        </Box>
      </div>
    </div>
  );
};

export default AddTaskModal;
