//CTO Review: subset of definitions, same as ones in Admin App, mneed to merge.

import { formattedDate } from "../constants/common";
import { IAvailabilityDetails } from "../type/availability";

//handle the prevent availiability overlaping
export const availabilityOverlap = (d1: any, data: IAvailabilityDetails[]) => {
    let overlap: any[] = [];

    data?.forEach((a) => {
      if (
        new Date(d1) <= new Date(formattedDate(a.endDate)) &&
        new Date(d1) >= new Date(formattedDate(a.beginDate))
      ) {
        overlap = [...a.availabilitySelectedDays];
      }
    });

    return overlap;
};

// sortData availability details
export function sortDataArray<T>(item: IAvailabilityDetails[]) {
    let data: IAvailabilityDetails[] = [];
    if (item?.length > 0) {
      data = [...item].sort(
        (a, b) =>
          (new Date(formattedDate(a.beginDate)) as any) -
          (new Date(formattedDate(b.beginDate)) as any)
      );
    }
    return data as T[];
}