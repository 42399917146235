import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import EndIcon from "../../assets/images/images-png/end.png";
import CalendarIcon from "../../assets/images/images-png/CalendarIcon.png";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import ClientImg from "../../assets/images/images-svg/client-large.svg";
import Schedule from "../../assets/images/images-png/Schedule.png";
import SessionForms from "./SessionForms";
import { Close } from "@mui/icons-material";
import "./session.scss";
import SessionUpdates from "./SessionUpdates";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { IEmployeePortalData } from "../../type/providerDetails";
import moment from "moment";
import {
  UpdateSession,
  GetSessionById,
  setCurrentSessionForm,
} from "../../redux/features/session/sessionSlice";
import {
  DefaultSessionForm,
  formatTimeTo24Hour,
  formatTimeToAMPM,
  SessionStatus,
} from "../../constants/session";
import ProviderImg from "../../assets/images/images-svg/provider-large-vertical.svg";

const SessionDetails = () => {
  //#region variable region
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const location = useLocation();
  const [selectedTab, setSelectedTab] = useState<string>("Summary");
  const [paths, setPath] = useState<string>("/sessions");
  const [openPopUp, setOpenPopup] = useState<boolean>(false);
  const DetailsTab = ["Summary", "Forms"];
  const [sessionEndTime, setSessionEndTime] = useState<string>("");
  const [approved, setApproved] = useState<{
    startTime: string;
    endTime: string;
  }>({
    startTime: "",
    endTime: "",
  });
  const currentSession = useAppSelector(
    (state) => state.sessionSlice.currentSession
  );
  const providerDetails = useAppSelector<IEmployeePortalData>(
    (state) => state.providerSlice.providerProfile
  );
  //#endregion

  //#region methods region
  const handleConfirmCloseSession = () => {
    setOpenPopup(true);
    const currentTime = new Date();
    const hours = currentTime.getHours().toString().padStart(2, "0");
    let minutes = currentTime.getMinutes().toString().padStart(2, "0");
    setSessionEndTime(`${hours}:${minutes}`);
    setApproved({
      startTime: currentSession
        ? formatTimeTo24Hour(currentSession?.sessionBeginTime)
        : "",
      endTime: `${hours}:${minutes}`,
    });
  };
  const closeSession = () => {
    setOpenPopup(false);
    if (currentSession?.id)
      dispatch(
        UpdateSession({
          id: currentSession?.id,
          status: SessionStatus.CLOSED,
          sessionEndTime: formatTimeToAMPM(sessionEndTime),
          approvalBeginTime: formatTimeToAMPM(approved.startTime),
          approvalEndTime: formatTimeToAMPM(approved.endTime),
        })
      );
  };

  const handleParentSignOut = (formName: string) => {
    let signInForm = currentSession?.requiredForms.find(
      (item) => item.name === formName
    );
    if (signInForm) {
      dispatch(setCurrentSessionForm(signInForm));
    }
    navigate("/patient/signOut");
  };

  //#endregion

  //#region useEffect region
  useEffect(() => {
    if (id) {
      dispatch(GetSessionById({ id }));
    }
    const data = localStorage.getItem("selectedTab");
    localStorage.setItem("previousPath", location.pathname);
    if (data) {
      setPath(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  //#endregion

  return (
    <Box className="sessionDetails">
      <Box className="details-header">
        <Box
          className="back-btn"
          onClick={() => {
            navigate(`/${paths.toLowerCase()}`);
            localStorage.removeItem("previousPath");
          }}
        >
          <KeyboardBackspaceIcon color="primary" />
          <Typography>Back</Typography>
        </Box>

        <Typography className="session-Title">
          {`Session: ${currentSession?.serviceSchedule.bookedProviderId.firstName} ${currentSession?.serviceSchedule.bookedProviderId.lastName}
           - ${currentSession?.serviceSchedule?.bookedClientId?.childFirstName} ${currentSession?.serviceSchedule?.bookedClientId?.childLastName}`}
        </Typography>
      </Box>

      {/* {selectedTab !== "Updates" &&
        !currentSession?.sessionSummeryData.sessionEndTime && (
          <Box className="closeSession">
            <Button
              variant="outlined"
              onClick={() => {
                setOpenPopup(true);
                const currentTime = new Date();
                const hours = currentTime
                  .getHours()
                  .toString()
                  .padStart(2, "0");
                let minutes = currentTime
                  .getMinutes()
                  .toString()
                  .padStart(2, "0");

                setSessionEndTime(`${hours}:${minutes}`);
              }}
            >
              <img src={EndIcon} alt="" />
              Close Session
            </Button>
          </Box>
        )} */}

      <Box className="details-tab">
        <ul className="tabs">
          {DetailsTab.map((item, i) => (
            <li
              className={selectedTab === item ? "active-li" : ""}
              key={i}
              onClick={() => setSelectedTab(item)}
            >
              {item}
            </li>
          ))}
        </ul>
      </Box>
      {selectedTab === "Summary" && (
        <Box className="session-summary">
          <Grid container columnSpacing={2} rowSpacing={2}>
            <Grid item xs={12} md={8} lg={9} rowSpacing={2}>
              <Box className="stepperStage">
                <Box
                  sx={{
                    display: "flex",
                    gap: "10px",
                    paddingLeft: "24px",
                    paddingTop: "15px",
                  }}
                >
                  <img src={Schedule} alt="" width={"26px"} height={"27px"} />
                  <Box>
                    <Typography className="card-title">
                      Session Process
                    </Typography>
                    <Typography className="carDescription">
                      Keep track of session forms and notes.
                    </Typography>
                  </Box>
                </Box>
                <ul>
                  {Object.values(SessionStatus).map((item, index) => (
                    <li
                      key={index}
                      className={
                        item === currentSession?.status
                          ? "stepperStage_item active-onBoard"
                          : "stepperStage_item"
                      }
                    >
                      <div className="stepperStage_item-link">
                        <span> {item}</span>
                      </div>
                    </li>
                  ))}
                </ul>
              </Box>
              <Box className="stepperStage p-25 ">
                <Box
                  sx={{
                    display: "flex",
                    gap: "10px",
                  }}
                >
                  <img src={Schedule} alt="" width={"26px"} height={"27px"} />
                  <Box>
                    <Typography className="card-title">
                      Sign In Procedure
                    </Typography>
                    <Box className="signIn-procedure">
                      <Typography>1. Click "Patient Sign In" to collect Sign-In form.</Typography>
                      {providerDetails.employmentDetails.id ===
                        currentSession?.serviceSchedule.bookedProviderId.id && (
                        <Button
                          variant="outlined"
                          onClick={() =>
                            handleParentSignOut(DefaultSessionForm.SIGN_IN_NOTE)
                          }
                        >
                          Patient Sign In
                        </Button>
                      )}
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box className="stepperStage p-25">
                <Box
                  sx={{
                    display: "flex",
                    gap: "10px",
                  }}
                >
                  <img src={Schedule} alt="" width={"26px"} height={"27px"} />
                  <Box>
                    <Typography className="card-title">
                      Sign Out Procedure
                    </Typography>
                    <Box className="signOut-procedure">
                      <Typography>
                        1. Click “Close Session” to finalize start and end time.
                      </Typography>
                      {currentSession?.status === SessionStatus.STARTED &&
                        providerDetails.employmentDetails.id ===
                          currentSession?.serviceSchedule.bookedProviderId
                            .id && (
                          <Button
                            variant="outlined"
                            onClick={handleConfirmCloseSession}
                          >
                            {/* <img src={EndIcon} alt="" /> */}
                            Close Session
                          </Button>
                        )}
                    </Box>
                    <Box className="signOut-procedure">
                      <Typography>2. Click "Parent Sign Out" to collect Sign Out Form.</Typography>
                      {providerDetails.employmentDetails.id ===
                        currentSession?.serviceSchedule.bookedProviderId.id && (
                        <Button
                          variant="outlined"
                          onClick={() =>
                            handleParentSignOut(
                              DefaultSessionForm.SIGN_OUT_NOTE
                            )
                          }
                        >
                          Parent Sign Out
                        </Button>
                      )}
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box className="stepperStage p-25">
                <Box
                  sx={{
                    display: "flex",
                    gap: "10px",
                  }}
                >
                  <img src={Schedule} alt="" width={"26px"} height={"27px"} />
                  <Box>
                    <Typography className="card-title">
                      Finalize Session Notes
                    </Typography>
                    <Typography margin={"20px 0px"}>
                      1. As Employee, go to “
                      <span
                        className="linkForm"
                        onClick={() => setSelectedTab("Forms")}
                      >
                        Forms
                      </span>
                      ” and open Session Note Form. You’ll need to complete and
                      submit Session Note Form.
                    </Typography>
                    <Typography>
                      2. As Supervisor, go to “
                      <span
                        className="linkForm"
                        onClick={() => setSelectedTab("Forms")}
                      >
                        Forms
                      </span>
                      ” and see submitted forms for review. Click on the link
                      button to Start your review process.
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} md={4} lg={3}>
              <Card className="summary-card">
                <CardContent>
                  <Box className="card-header">Session Summary</Box>
                  <Box marginTop={"38px"}>
                    <Box className="sheduleDetails">
                      <Box>
                        <img src={CalendarIcon} alt="" />
                      </Box>
                      <Box>
                        <Typography className="sub-list">Schedule</Typography>
                        <Box className="timeDetails">
                          <Typography>
                            {moment(
                              currentSession?.serviceSchedule.specificDate
                            ).format("dddd")}
                          </Typography>
                          <Typography>
                            {currentSession?.serviceSchedule.fromTime} -{" "}
                            {currentSession?.serviceSchedule.toTime}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                    <Box className="sheduleDetails">
                      <Box>
                        <AccessTimeIcon color="primary" />
                      </Box>
                      <Box>
                        <Typography className="sub-list">Actual</Typography>
                        <Box>
                          <Typography>
                            {moment(currentSession?.sessionBeginDtm).format(
                              "dddd, M/D/YYYY"
                            )}
                          </Typography>

                          {currentSession?.sessionEndTime ? (
                            <Typography>
                              {currentSession?.sessionBeginTime} -{" "}
                              {currentSession.sessionEndTime}
                            </Typography>
                          ) : (
                            <Typography>
                              {currentSession?.sessionBeginTime} - not closed yet
                            </Typography>
                          )}
                        </Box>
                      </Box>
                    </Box>
                    <Box className="sheduleDetails">
                      <Box>
                        <PersonOutlineIcon color="primary" />
                      </Box>
                      <Box>
                        <Typography className="sub-list">Participants</Typography>
                        <Box className="userDetails">
                          <span className="userMapCart__img">
                            <img
                              src={
                                currentSession?.serviceSchedule.bookedClientId
                                  .clientProfile.url || ClientImg
                              }
                              className="userSmallCircle"
                              alt={""}
                            />
                          </span>
                          <Typography className="ClientName">
                            {currentSession?.serviceSchedule?.bookedClientId?.childFirstName}{" "}
                            { currentSession?.serviceSchedule?.bookedClientId?.childLastName}{" - "}
                            {currentSession?.serviceSchedule?.authCode}
                          </Typography>
                        </Box>
                        <Box className="userDetails">
                          <span className="userMapCart__img">
                            <img
                              src={
                                currentSession?.serviceSchedule.bookedProviderId
                                  .providerProfile.url || ProviderImg
                              }
                              className="userSmallCircle"
                              alt={""}
                            />
                          </span>
                          <Typography className="ClientName">
                            {
                              currentSession?.serviceSchedule?.bookedProviderId
                                .firstName
                            }{" "}
                            {
                              currentSession?.serviceSchedule?.bookedProviderId
                                .lastName
                            }
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
      )}
      {selectedTab === "Forms" && <SessionForms />}
      {selectedTab === "Updates" && <SessionUpdates />}

      {openPopUp && (
        <Dialog open={true} className="largeModel">
          <Grid>
            <Box className="largeModel__head">
              <Typography variant="h5" className="largeModel__title">
                Close Session
              </Typography>
              <Button
                className="largeModel__closeBtn"
                onClick={() => {
                  setOpenPopup(false);
                }}
              >
                <Close />
              </Button>
            </Box>
            <Box className="largeModel__body" padding={"35px"}>
              <Typography fontSize={"18px"} fontWeight={500}>
                You are now closing the session, check the time we would log the
                system.
              </Typography>
              <Box className="flex" gap={"50px"} marginTop={"25px"}>
                <Box>
                  <Typography
                    className="time-title"
                    fontWeight={700}
                    fontSize={"16px"}
                  >
                    Start Time
                  </Typography>
                  <TextField
                    className="closeTime"
                    id="startTime"
                    type="time"
                    value={approved.startTime}
                    onChange={(e) => {
                      setApproved({ ...approved, startTime: e.target.value });
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      step: 300,
                    }}
                  />
                </Box>
                <Box>
                  <Typography fontWeight={700} fontSize={"16px"}>
                    End Time
                  </Typography>
                  <TextField
                    className="closeTime"
                    id="endTime"
                    type="time"
                    value={approved.endTime}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      step: 300,
                    }}
                    onChange={(e) => {
                      setApproved({ ...approved, endTime: e.target.value });
                    }}
                  />
                </Box>
              </Box>
            </Box>
            <Box className="stepperForm__footer">
              <Button
                type="submit"
                color="inherit"
                className="border-button"
                onClick={() => setOpenPopup(false)}
              >
                Cancel
              </Button>
              <div></div>
              <Box sx={{ flex: "1 1 auto" }} />
              <Button className="button" onClick={closeSession}>
                Confirm and Close
              </Button>
            </Box>
          </Grid>
        </Dialog>
      )}
    </Box>
  );
};

export default SessionDetails;
