import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import formService from "../../../service/form.service";
import { setResponseValue } from "../api-response";
import { IOnboardingtData } from "../../../type/form";
import { OnboardingFormData } from "../../../constants/form";

const initialState: IOnboardingtData = {
    ...OnboardingFormData
};

const providerOnboardingFormSlice = createSlice({
  name: "providerOnboardingFormSlice",
  initialState: initialState,
  reducers: {},
  extraReducers:(builder) =>{
      builder.addCase(GetAssessmentById.fulfilled, (state,action) => {
        return {
          ...state,
          onBoardingform: action.payload
        }
      })
  },
});

//get all Onboarding forms
// export const GetOnboardingForms = createAsyncThunk(
//   `/getOnboardingforms`,
//   async ({ formType }: { formType: string }, { dispatch }) => {
//     try {
//       const { data, status } = await formService.getAssessmentForm(formType);

//       if (status) {
//         dispatch(setResponseValue({ name: "success", value: true }));
//         dispatch(setResponseValue({ name: "message", value: data.message }));
//         return data;
//       }
//     } catch (e: any) {
//       dispatch(setResponseValue({ name: "error", value: true }));
//       dispatch(
//         setResponseValue({
//           name: "message",
//           value: e.message,
//         })
//       );
//     }
//   }
// );

//get boarding form by id
export const GetAssessmentById = createAsyncThunk(
  "/getAssessmentById",
  async ({ id }: { id: string }, { dispatch }) => {
    try {
      const { data } = await formService.getOnboardingById(id);
      return data;
    } catch (e: any) {
      throw dispatch(
        setResponseValue({
          name: "message",
          value: e.message,
        })
      );
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

//update form result
export const UpdateFormResult = createAsyncThunk(
  "/updateFormResult",
  async (
    {
      id,
      result,
      isComplete,
    }: { id: string; result: any; isComplete: boolean },
    { getState, dispatch }
  ) => {
    let res = {
      result,
      isComplete: isComplete,
    };
    try {
      const { data , message } = await formService.updateFormresult(id, res);
      dispatch(setResponseValue({ name: "success", value: true }));
      dispatch(setResponseValue({ name: "message", value: message }));
      return data;
    } catch (e: any) {
      throw dispatch(
        setResponseValue({
          name: "message",
          value: e.message,
        })
      );
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

export default providerOnboardingFormSlice;