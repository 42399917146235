import axios from "../axios/axios";
import { SingleResponse } from "../type/api-response";
import { AllUserMessageI, IMessage } from "../type/message";

//Get Parent Message list
// const getParentMessage = async (id: string) => {
//   try {
//     const { data } = await axios.get(`/parentMessage/${id}`);

//     return data;
//   } catch (e: any) {
//     throw e?.response?.data;
//   }
// };

const getMessageByEntityId = async (entityId: string) => {
  try {
    const { data } = await axios.get<SingleResponse<AllUserMessageI>>(
      `/message/${entityId}`
    );

    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

// create message
const postCreateMessage = async (
  payloadData: any,
  entityId: string,
  files?: any[]
) => {
  const formData = new FormData();
  formData.append("subject", payloadData.subject);
  formData.append("message", payloadData.message);
  formData.append("whoCanView", JSON.stringify(payloadData.whoCanView));
  formData.append("type", payloadData.type);
  formData.append("from", payloadData.from);
  formData.append("entityType", payloadData.entityType);
  files?.forEach((file) => {
    if (!file.fileKey) {
      formData.append("file", file);
    }
  });
  try {
    const { data } = await axios.post(`/message/${entityId}`, formData);
    return data;
  } catch (e: any) {
    console.log(e);
  }
};

//Deprecated this!!
// //Get Current Message list
// const getCurrentMessage = async () => {
//   try {
//     const { data } = await axios.get(`/employee/activeMessage`);
//     return data;
//   } catch (e: any) {
//     throw e?.response?.data;
//   }
// };

//update read-unread data
const updateStatus = async (id: string[]) => {
  try {
    const data = await axios.patch(`/message`, { id });
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

//patch message
export const patchComments = async (
  messageId: string | undefined,
  entityId: string,
  comment: string,
  entityType: string,
  files?: any[]
) => {
  const formData = new FormData();
  formData.append("entityId", entityId);
  formData.append("comment", comment);
  formData.append("entityType", entityType);
  files?.forEach((file) => {
    if (!file.fileKey) {
      formData.append("file", file);
    }
  });
  try {
    const data = await axios.post(`/comment/${messageId}`, formData);
    return data.data;
  } catch (e: any) {
    throw e.response.data;
  }
};

export const getMessageTranslation = async (id: string) => {
  try {
    const { data } = await axios.get(`/translate/${id}?type=message`);
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

const MessagesSlice = {
  // getParentMessage,
  // getCurrentMessage,
  postCreateMessage,
  getMessageByEntityId,
  updateStatus,
  patchComments,
  getMessageTranslation,
};

export default MessagesSlice;
