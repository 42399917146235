import {
  Box,
  Button,
  Dialog,
  Grid,
  Paper,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  ButtonBack,
  ButtonNext,
  CarouselProvider,
  Slide,
  Slider,
} from "pure-react-carousel";
import { useEffect, useState } from "react";
import { StyledTable, StyledTableCell, StyledTableRow } from "./Session";
import { ReactComponent as LeftArrow } from "../../assets/images/images-svg/leftArrow.svg";
import { ReactComponent as RightArrow } from "../../assets/images/images-svg/rightArrow.svg";
import ClientImg from "../../assets/images/images-svg/client-large.svg";
import Review from "../../assets/images/images-png/Export.png";
import Download from "../../assets/images/images-png/Download.png";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { ISessionForm } from "../../type/session";
import {
  SendSessionForm,
  setCurrentSessionForm,
} from "../../redux/features/session/sessionSlice";
import Close from "@mui/icons-material/Close";
import moment from "moment";
import { FormRequestStatus } from "../../constants/session";
import { IEmployeePortalData } from "../../type/providerDetails";

const SessionForms = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isDesktop, setDesktop] = useState<number>(4); // Number of visible slides
  const [activeIndex, setActiveIndex] = useState<number>(0); // Starting index
  // const [openReview, setOpenReview] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [selectedFormId, setSelectedFormId] = useState<string>();
  const providerDetails = useAppSelector<IEmployeePortalData>(
    (state) => state.providerSlice.providerProfile
  );
  const currentSession = useAppSelector(
    (state) => state.sessionSlice.currentSession
  );
  // Set the number of slides visible based on screen size (adaptive behavior)
  useEffect(() => {
    const updateScreenWidth = () => {
      if (window.innerWidth > 1250) {
        setDesktop(4);
      } else if (window.innerWidth > 991) {
        setDesktop(3);
      } else if (window.innerWidth > 650) {
        setDesktop(2);
      } else {
        setDesktop(1);
      }
    };
    updateScreenWidth();
    window.addEventListener("resize", updateScreenWidth);

    return () => {
      window.removeEventListener("resize", updateScreenWidth);
    };
  }, []);

  // Left scroll button logic
  const handleLeft = () => {
    if (activeIndex > 0) {
      setActiveIndex((prevIndex) => Math.max(prevIndex - isDesktop, 0));
    }
  };

  // Right scroll button logic
  const handleRight = () => {
    if (!currentSession) {
      return;
    }
    if (activeIndex + isDesktop < currentSession?.requiredForms.length) {
      setActiveIndex((prevIndex) =>
        Math.min(
          prevIndex + isDesktop,
          currentSession?.requiredForms.length - 1
        )
      );
    }
  };
  const showArrow =
    currentSession && isDesktop < currentSession?.requiredForms.length;
  const handleSendForm = () => {
    setOpenConfirmation(false);
    if (
      currentSession?.id &&
      selectedFormId &&
      currentSession.serviceSchedule.bookedClientId
    )
      dispatch(
        SendSessionForm({
          sessionId: currentSession.id,
          formId: selectedFormId,
          entityType: "Client",
          entityId: currentSession.serviceSchedule.bookedClientId.id,
          authCode: currentSession.serviceSchedule.authCode,
          authId: currentSession.serviceSchedule.authId,
          isComplete: false,
        })
      );
  };
  const handleFillForm = (form: ISessionForm) => {
    dispatch(setCurrentSessionForm(form));
    navigate("/form/sessionForm", {
      state: {
        type: "Session",
      },
    });
  };
  return (
    <Box className="sessionForms">
      <CarouselProvider
        naturalSlideWidth={100}
        naturalSlideHeight={125}
        totalSlides={currentSession ? currentSession?.requiredForms.length : 0}
        visibleSlides={isDesktop}
        step={isDesktop}
        // currentSlide={activeIndex}
        orientation="horizontal"
        className="cardSlider"
      >
        <Slider className="slider">
          {currentSession?.requiredForms.map((item, i) => (
            <Slide index={i} key={item.id} className="slide intakeForm">
              <Box className="intakeForm_inner">
                <Box
                  className="intakeForm_inner_Box"
                  sx={
                    currentSession.serviceSchedule.bookedProviderId.id ===
                    providerDetails.employmentDetails.id
                      ? { marginBottom: "0px" }
                      : { marginBottom: "30px" }
                  }
                >
                  <div className="content">{item.name}</div>
                </Box>
                {currentSession.serviceSchedule.bookedProviderId.id ===
                  providerDetails.employmentDetails.id && (
                  <Box className="intakeForm_bottom">
                    <Button
                      variant="outlined"
                      onClick={() => {
                        setOpenConfirmation(true);
                        setSelectedFormId(item.id);
                      }}
                    >
                      Send
                    </Button>
                    <Button
                      variant="contained"
                      onClick={() => handleFillForm(item)}
                    >
                      Fill Form
                    </Button>
                  </Box>
                )}
              </Box>
            </Slide>
          ))}
        </Slider>
        {showArrow && (
          <ButtonBack className="sliderNavBtn" onClick={handleLeft}>
            <LeftArrow />
          </ButtonBack>
        )}
        {showArrow && (
          <ButtonNext className="sliderNavBtn" onClick={handleRight}>
            <RightArrow />
          </ButtonNext>
        )}
      </CarouselProvider>
      <Box className="session-Request">
        <Typography className="formRequest-title">
          Request & Submission
        </Typography>
        <TableContainer component={Paper} className="tableResponsive">
          <StyledTable
            sx={{
              minWidth: 700,
              borderCollapse: "separate",
              cursor: "pointer",
              borderSpacing: "0 8px",
            }}
            aria-label="customized table"
          >
            <TableHead>
              <TableRow>
              <StyledTableCell>Type</StyledTableCell>
                <StyledTableCell>Person</StyledTableCell>
                <StyledTableCell>Form</StyledTableCell>
                <StyledTableCell>Send Date</StyledTableCell>
                <StyledTableCell>Submit Date</StyledTableCell>
                <StyledTableCell>Review Date</StyledTableCell>
                <StyledTableCell>Status</StyledTableCell>
                <StyledTableCell>Data Review</StyledTableCell>
                <StyledTableCell>Result</StyledTableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {currentSession?.formRequests &&
                currentSession?.formRequests.map((item, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell
                      style={{ fontSize: "16px", fontWeight: "500" }}
                    >
                      {item?.entityType}
                    </StyledTableCell>
                    <StyledTableCell>
                      <Box className="flex">
                        <span className="userMapCart__img">
                          <img
                            src={item.profileUrl || ClientImg}
                            className="userSmallCircle"
                            alt={""}
                          />
                        </span>
                        <Typography className="ClientName">
                          {item.entityName}
                        </Typography>
                      </Box>
                    </StyledTableCell>
                    <StyledTableCell
                      style={{ fontSize: "16px", fontWeight: "500" }}
                    >
                      {item?.formName || item.form?.name}
                    </StyledTableCell>
                    {/* sent Date */}
                    <StyledTableCell>
                      {moment(item.sentDtm).format("ll")}
                    </StyledTableCell>
                    {/* Submit date */}

                    <StyledTableCell>
                      {item.submitDtm ? (
                        <Typography>
                          {moment(item.submitDtm).format("ll")}
                        </Typography>
                      ) : (
                        <Box className="dateMessage">Not Submitted Yet</Box>
                      )}
                    </StyledTableCell>
                    {/* review Date */}
                    <StyledTableCell>
                      {item?.reviewDtm ? (
                        moment(item?.reviewDtm).format("ll")
                      ) : (
                        <Box className="dateMessage">Not Sign Yet</Box>
                      )}
                    </StyledTableCell>
                    <StyledTableCell>{item.status}</StyledTableCell>
                    <StyledTableCell>
                      <Box
                        className="flex"
                        onClick={() => {
                          if (item.status !== FormRequestStatus.Sent)
                            navigate(`/formReview/${item.id}`);
                        }}
                      >
                        <img src={Review} alt="" />
                        {(item.status === FormRequestStatus.Complete ||
                          item.status === FormRequestStatus.InReview ||
                          item.status === FormRequestStatus.Submitted) && (
                          <Typography>
                            {item.reviewData?.checkList?.length || 0} /{" "}
                            {item.checkListCount ||
                              item.form?.checkList?.length}{" "}
                            reviewed
                          </Typography>
                        )}
                      </Box>
                    </StyledTableCell>
                    <StyledTableCell>
                      {/* download */}
                      {item.pdfUrl && (
                        <a href={item?.pdfUrl}>
                          <img src={Download} alt="" />
                        </a>
                      )}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
            </TableBody>
          </StyledTable>
        </TableContainer>
      </Box>

      <Dialog open={openConfirmation} className="largeModel">
        <Grid>
          <Box className="largeModel__head">
            <Typography variant="h5" className="largeModel__title">
              Send Confirmation
            </Typography>
            <Button
              className="largeModel__closeBtn"
              onClick={() => {
                setOpenConfirmation(false);
              }}
            >
              <Close />
            </Button>
          </Box>
          <Box className="largeModel__body" padding={"35px"}>
            <Typography fontSize={"18px"} fontWeight={500}>
              This Form will be sent to :
              {currentSession?.serviceSchedule?.bookedClientId.childFirstName}{" "}
              {currentSession?.serviceSchedule?.bookedClientId.childLastName}
            </Typography>
            <Box className="flex" gap={"50px"} marginTop={"25px"}></Box>
          </Box>
          <Box className="stepperForm__footer">
            <Button
              type="submit"
              color="inherit"
              className="border-button"
              onClick={() => setOpenConfirmation(false)}
            >
              Cancel
            </Button>
            <div></div>
            <Box sx={{ flex: "1 1 auto" }} />
            <Button className="button" onClick={() => handleSendForm()}>
              Confirm and Send
            </Button>
          </Box>
        </Grid>
      </Dialog>
    </Box>
  );
};

export default SessionForms;
