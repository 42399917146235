import SurveyComponent from "../pages/onBoardingForm/SurveyComponent";

class GlobalAppUrls {
  public Client = {
    Home: "/home",
    Schedule: "/schedules",
    Document: "/documents",
    Messages: "/messages",
    Tasks:"/tasks",
    Sessions:"/sessions",
    SessionFormReview:"/formReview/:id",
    SessionDetails:"/session-details/:id",
    OnBoardingForm:"/form/:id",
    patientSignOut:"/patient/signOut"
  };

  public Server = {
    Account: {
      Login: "/api/v1/users/login",
      Logout: "/api/v1/users/logout",
    },
  };
}

export const AppUrls = new GlobalAppUrls();
