import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ITask, ITaskType, checklist } from "../../../type/taskManager";
import { setResponseValue } from "../api-response";
import TaskManagerService from "../../../service/taskManager.service";

interface ITaskManager {
  tasks: ITask[];
  taskTypes: ITaskType[];
}
const initialState: ITaskManager = {
  tasks: [],
  taskTypes: [],
};
const taskManagerSlice = createSlice({
  name: "taskManagerSlice",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(GetMyTask.fulfilled, (state, action) => {
      if (action.payload !== undefined) {
        return {
          ...state,
          tasks: action.payload,
        };
      }
    });
    builder.addCase(PatchTaskById.fulfilled, (state, action) => {
      if (action.payload !== undefined) {
        const data = state.tasks.map((item: ITask) => {
          if (item.id === action.payload?.id) {
            return (item = action.payload.data);
          } else {
            return item;
          }
        });
        return {
          ...state,
          tasks: data,
        };
      }
    });
    builder.addCase(UpdateTaskbyId.fulfilled, (state, action) => {
      if (action.payload !== undefined) {
        const data = state.tasks.map((item: ITask) => {
          if (item.id === action.payload?.id) {
            return (item = action.payload?.data);
          } else {
            return item;
          }
        });
        return {
          ...state,
          tasks: data,
        };
      }
    });
    builder.addCase(GetAllTaskType.fulfilled, (state, action) => {
      if (action.payload !== undefined) {
        return { ...state, taskTypes: action.payload };
      }
    });
  },
});

//get current user Tasks
export const GetMyTask = createAsyncThunk(
  "get/task",
  async (_, { dispatch }) => {
    try {
      dispatch(setResponseValue({ name: "pending", value: true }));
      const { data } = await TaskManagerService.getMyTasks();
      return data;
    } catch (error: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(setResponseValue({ name: "message", value: error.message }));
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);
//update my Task
export const PatchTaskById = createAsyncThunk(
  "assign/task",
  async (
    {
      id,
      type,
      value,
    }: {
      id: string;
      type: string;
      value: string;
    },
    { dispatch }
  ) => {
    const payload = {
      type: type,
      value: value,
    };
    try {
      dispatch(setResponseValue({ name: "pending", value: true }));
      const { data, message } = await TaskManagerService.patchTaskById(
        id,
        payload
      );
      if (data) {
        dispatch(setResponseValue({ name: "success", value: true }));
        dispatch(setResponseValue({ name: "message", value: message }));
        return { data, id };
      }
    } catch (error: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(setResponseValue({ name: "message", value: error.message }));
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);
//update my Task
export const UpdateTaskbyId = createAsyncThunk(
  "update/taskbyId",
  async (
    {
      id,
      name,
      description,
      type,
      priority,
      status,
      checklist,
      dueDate,
    }: {
      id: string;
      name: string;
      description: string;
      type: string;
      priority: string;
      status: string;
      checklist: checklist[];
      dueDate: Date;
    },
    { dispatch }
  ) => {
    const payload = {
      name: name,
      description: description,
      type: type,
      priority: priority,
      status: status,
      checklist: checklist,
      dueDate: dueDate,
    };
    try {
      dispatch(setResponseValue({ name: "pending", value: true }));
      const { data, message } = await TaskManagerService.updateTaskById(
        id,
        payload
      );
      if (data) {
        dispatch(setResponseValue({ name: "success", value: true }));
        dispatch(setResponseValue({ name: "message", value: message }));
        return { data, id };
      }
    } catch (error: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(setResponseValue({ name: "message", value: error.message }));
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

export const GetAllTaskType = createAsyncThunk(
  "get/tasktype",
  async (_, { dispatch }) => {
    try {
      dispatch(setResponseValue({ name: "pending", value: true }));
      const { data } = await TaskManagerService.getAllTaskType();
      return data;
    } catch (error: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(setResponseValue({ name: "message", value: error.message }));
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

export default taskManagerSlice;
