import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../redux/store";
import Loader from "../../layouts/loader/Loader";
import ApiResponse from "./ApiResponse";
import { setResponseValue } from "../../redux/features/api-response";

const GlobalApiResponse = () => {
  const dispatch = useAppDispatch();
  //stores success message
  const success = useSelector<RootState, boolean>(
    (state) => state.responseSlice.success
  );

  //stores pending message
  const pending = useSelector<RootState, boolean>(
    (state) => state.responseSlice.pending
  );

  //stores error message
  const error = useSelector<RootState, boolean>(
    (state) => state.responseSlice.error
  );

  //stores message
  const message = useSelector<RootState, string>(
    (state) => state.responseSlice.message
  );

  //handles close api response message
  const handleClosePopup = (closeModal: { name: string; value: boolean }) => {
    dispatch(setResponseValue(closeModal));
  };

  return (
    <div>
      {pending && <Loader isShow={pending} />}
      {(success || error) && (
        <ApiResponse
          message={message}
          isOpen={success || error}
          isSuccess={success ? true : false}
          closeModal={
            success
              ? { name: "success", value: false }
              : { name: "error", value: false }
          }
          handleClosePopup={(closeModal: { name: string; value: boolean }) =>
            handleClosePopup(closeModal)
          }
        />
      )}
    </div>
  );
};

export default GlobalApiResponse;
