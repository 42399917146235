import { Box, Step, StepLabel, Stepper, Typography } from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { Model } from "survey-core";
import { Survey } from "survey-react-ui";
import {
  SendSessionForm,
  setCurrentSessionForm,
} from "../../redux/features/session/sessionSlice";
import { DefaultSessionForm } from "../../constants/session";
import { IEmployeePortalData } from "../../type/providerDetails";

const SessionSignout: React.FC = () => {
  //#region variable region
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const signOutStepLabel = ["Sign In Note", "Sign Out Note"];
  const [activeStep, setActiveStep] = useState<number>(0);
  const [previousRoute, setPreviousRoute] = useState<string>();
  const providerDetails = useAppSelector<IEmployeePortalData>(
    (state) => state.providerSlice.providerProfile
  );
  const { currentSession, currentSessionForm } = useAppSelector(
    (state) => state.sessionSlice
  );

  //#endregion

  //#region survey region
  const [survey, setSurvey] = useState(
    new Model(currentSessionForm?.formSchema)
  );
  survey.showCompletedPage = false;
  survey.onAfterRenderQuestion.add(function (survey, options) {
    if (options.question.name === "question3") {
      const inputElement = options.htmlElement.querySelector("input");
      if (inputElement) {
        inputElement.disabled = true;
      }
    }
  });
  survey.onComplete.add((sender, options) => {
    if (currentSession && currentSessionForm) {
      dispatch(
        SendSessionForm({
          authCode: currentSession?.serviceSchedule.authCode,
          authId: currentSession.serviceSchedule.authId,
          entityId: providerDetails.employmentDetails.id,
          entityType: "Provider",
          formId: currentSessionForm?.id,
          formData: survey.data,
          sessionId: currentSession.id,
          isComplete: true,
          formName: currentSessionForm.name,
        })
      );
      setActiveStep(activeStep + 1);
      dispatch(setCurrentSessionForm(null));
    }
  });

  //#endregion

  //#region methods region
  const formatLocalDateTime = (date: Date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };

  const handleSteps = (activeStep: number) => {
    if (activeStep === 1) {
      const signOutForm = currentSession?.requiredForms.find(
        (item) => item.name === DefaultSessionForm.SIGN_OUT_NOTE
      );
      dispatch(setCurrentSessionForm(signOutForm));
    }
  };
  //#endregion

  //#region useEffect region
  useEffect(() => {
    handleSteps(activeStep);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStep]);

  useEffect(() => {
    if (currentSessionForm && currentSession && survey) {
      if(currentSessionForm.name === DefaultSessionForm.SIGN_OUT_NOTE){
        setActiveStep(1)
      }
      let surveyData = new Model(currentSessionForm?.formSchema);
      // Prepopulate form form sign and signOut
      surveyData.data = {
        question1:
          currentSession.serviceSchedule?.bookedClientId.childFirstName || "",
        question2:
          currentSession.serviceSchedule?.bookedClientId.childLastName || "",
        question3:
          currentSessionForm.name === DefaultSessionForm.SIGN_IN_NOTE
            ? formatLocalDateTime(
                new Date(currentSession.serviceSchedule.createdAt)
              )
            : formatLocalDateTime(new Date()),
      };
      setSurvey(surveyData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSessionForm?.id, currentSession]);

  useEffect(() => {
    const path = localStorage.getItem("previousPath");
    if (path) {
      setPreviousRoute(path);
    }
  }, []);

  //#endregion

  return (
    <div className="patientSignOut">
      
      <Box className="signOutHeader">
        <Box className="flex">
          <Box
            className="back-btn"
            onClick={() => {
              if (previousRoute) {
                navigate(previousRoute);
              } else {
                navigate(-1);
              }
            }}
          >
            <KeyboardBackspaceIcon color="primary" />
            <Typography>Back</Typography>
          </Box>
          <Typography className="title">Patient Sign Out</Typography>
        </Box>

        <Box className="largeModel__body">
          <Stepper
            activeStep={activeStep}
            sx={{ py: 3 }}
            alternativeLabel
            className="largeModel__stepperHead"
          >
            {signOutStepLabel.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
      </Box>
      <Box>
        {activeStep === 2 ? (
          <Typography className="signOutComplete">
            Sign Out Completed.
          </Typography>
        ) : (
          <Box bgcolor={"#f3f3f3"} className="surveyFormBlock">
            <Survey model={survey} />
          </Box>
        )}
      </Box>
    </div>
  );
};

export default SessionSignout;
