import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  TextFieldProps,
} from "@mui/material";
import { ValidatorForm } from "react-material-ui-form-validator";
import moment from "moment";
import DeleteIcon from "@mui/icons-material/Delete";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch,  } from "../../redux/store";
import { IAvailabilityDetails, ISelectedDay } from "../../type/availability";
import { formattedDate } from "../../constants/common";
import {
  addNewAvailability,
  deleteAvailability,
  deleteSingleAvailability,
  removeAvailability,
  setBeginDate,
  setDay,
  setEndDate,
  setFromTime,
  setProviderSelectedDays,
  setToTime,
} from "../../redux/features/providerSlice";
import {
  IAvailabilityTimes,
  IAvailabilityTimesForSchedulesBars,
  IWeekDays,
  defaultProviderAvailabilityDetail,
} from "../../constants/availability";
import { IAvailabilityDays } from "../../type/common";
import "./availabilityForm.scss";
import { sortDataArray } from "../../utils/AvailabilityUtils";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface IAvailabilityDetailsForm {
  updateHandler: () => void;
}

const AvailabilityForm: React.FC<IAvailabilityDetailsForm> = ({
  updateHandler,
}) => {
  const dispatch = useAppDispatch();

  //Availability details object to manage the redux
  const availabilityDetails = useSelector<RootState, IAvailabilityDetails[]>(
    (state) => state.providerSlice.providerProfile.availabilityDetails
  );

  //new error
  const [newError, setNewError] = useState<boolean>(false);

  //time error
  const [timeError, setTimeError] = useState<boolean>(false);

  //availability details modal handler
  const [isEditAvailabilityModal, setIsEditAvailabilityModal] =
    useState<boolean>(false);

  //store availability Index
  const [availabilityIndex, setAvailabilityIndex] = useState<number>(0);

  //Store availability startDate
  const [startDate, setStartDate] = useState<Dayjs | null | any>(
    availabilityDetails[availabilityIndex]?.beginDate
  );

  //Store availability endDate
  const [endDate, setendDate] = useState<Dayjs | null | any>(
    availabilityDetails[availabilityIndex]?.endDate
  );

  //handle the Error to show the warnings
  const [hasError, setHasError] = useState<boolean>(false);

  //show for warning message
  const [errorMessage, setErrorMessage] = useState<string>("");

  //dayError
  const [dayError, setDayError] = useState<boolean>(false);

  //time overlap on same day error
  const [timeErrorMessage, setTimeErrorMessage] = useState<string>("");

  //handle the prevent availiability overlaping
  const availabilityOverlap = (d1: any, availabilityIndex: number) => {
    let overlap = false;

    let val: IAvailabilityDetails[] = availabilityDetails.filter(
      (i) => i.id === ""
    );

    let data: IAvailabilityDetails[] = [];
    if (val.length > 0) {
      data = availabilityDetails.filter((i) => i.id !== "");
    } else {
      data = availabilityDetails.filter(
        (i, index: number) => index !== availabilityIndex
      );
    }

    data.forEach((a) => {
      if (
        new Date(formattedDate(d1)) <= new Date(formattedDate(a.endDate)) &&
        new Date(formattedDate(d1)) >= new Date(formattedDate(a.beginDate))
      ) {
        overlap = true;
      }
    });
    setHasError(overlap);

    if (overlap) {
      setErrorMessage(
        "Please select other date, date should not overlap previous date "
      );
    }

    return overlap;
  };

  //Handles the start time logic
  const handleFromTime = (
    e: SelectChangeEvent<string>,
    index: number,
    IAvailabilityTimesForSchedulesBars: string[]
  ): void => {
    dispatch(
      setFromTime({
        availabilityIndex: availabilityIndex,
        index: index,
        value: e.target.value,
      })
    );
  };

  //Handles the end Time logic
  const handleToTime = (e: SelectChangeEvent<string>, index: number): void => {
    dispatch(
      setToTime({
        availabilityIndex: availabilityIndex,
        index: index,
        value: e.target.value,
      })
    );
  };

  //create duplicate availability
  const duplicateAvailability = (index: number, item: ISelectedDay): void => {
    const newAvailability = [
      ...availabilityDetails[availabilityIndex]?.selectedDays,
    ];
    newAvailability.splice(index, 0, item);
    dispatch(setProviderSelectedDays({ availabilityIndex, newAvailability }));
  };

  //Returns the drop down values for start and end time
  const getValues = (index: number): JSX.Element[] => {
    const data: JSX.Element[] = [];
    if (index < IAvailabilityTimes.length) {
      for (let i = index; i < IAvailabilityTimes.length; i++) {
        data.push(
          <MenuItem key={i} value={IAvailabilityTimes[i].value}>
            {IAvailabilityTimes[i].value}
          </MenuItem>
        );
      }
    }
    return data;
  };

  //returns day index
  const returnDayIndex = (day: string): number => {
    return IWeekDays.indexOf(day);
  };

  //gets index from time
  const getIndexFromTime = (val: string): number => {
    for (let i = 0; i < IAvailabilityTimesForSchedulesBars.length; i++) {
      if (IAvailabilityTimesForSchedulesBars[i] === val) return i;
    }
    return -1;
  };

  // handle start date of authperiod
  const handleStartDate = (newValue: Dayjs | null) => {
    setStartDate(newValue);
    const obj = {
      startDate: dayjs(newValue).format("YYYY-MM-DD"),
    };

    if (obj.startDate !== "Invalid Date") {
      dispatch(
        setBeginDate({
          availabilityIndex: availabilityIndex,
          beginDate: obj.startDate,
        })
      );
    }
    if (availabilityIndex > 0) {
      if (
        !availabilityOverlap(
          dayjs(newValue).format("YYYY-MM-DD"),
          availabilityIndex
        )
      ) {
        setHasError(false);
      } else {
        setHasError(true);
      }
    }
  };

  //handle enddate of authperiod
  const handleEndDate = (newValue: Dayjs | null) => {
    setendDate(newValue);
    const obj = {
      endDate: dayjs(newValue).format("YYYY-MM-DD"),
      startDate: dayjs(startDate).format("YYYY-MM-DD"),
    };

    if (obj.endDate !== "Invalid Date") {
      dispatch(
        setEndDate({
          availabilityIndex: availabilityIndex,
          endDate: obj.endDate,
        })
      );
      setHasError(false);
    }

    if (availabilityIndex > 0) {
      if (
        !availabilityOverlap(
          dayjs(newValue).format("YYYY-MM-DD"),
          availabilityIndex
        )
      ) {
        setHasError(false);
      }
    }
  };

  // handle form submit
  const handleSubmit = () => {
    if (!availabilityDetails[availabilityIndex]?.endDate) {
    }
    let newAvailabilityArray: IAvailabilityDays[][] = [
      [],
      [],
      [],
      [],
      [],
      [],
      [],
    ];
    if (
      availabilityDetails[availabilityIndex].selectedDays[0].dayOfWeek
        .length === 0 ||
      availabilityDetails[availabilityIndex].selectedDays[0].startTime
        .length === 0 ||
      availabilityDetails[availabilityIndex].selectedDays[0].endTime.length ===
        0
    ) {
      setDayError(true);
      return;
    }
    availabilityDetails[availabilityIndex].selectedDays?.forEach(
      (item: ISelectedDay) => {
        let index = returnDayIndex(item.dayOfWeek);
        let day = {
          dayOfWeek: item.dayOfWeek,
          startIndex: getIndexFromTime(item.startTime),
          endIndex: getIndexFromTime(item.endTime),
        };
        if (day.startIndex < 0 || day.endIndex < 0 || index < 0) {
          setTimeError(true);
          setTimeout(() => {
            setTimeError(false);
          }, 3000);
          return;
        }
        newAvailabilityArray[index].push(day);
      }
    );
    newAvailabilityArray = newAvailabilityArray.map(
      (item: IAvailabilityDays[]) => {
        return item.sort(
          (a: IAvailabilityDays, b: IAvailabilityDays) =>
            a.startIndex - b.startIndex
        );
      }
    );
    let flag = false;
    newAvailabilityArray.forEach((item) => {
      for (let i = 0; i < item.length - 1; i++) {
        if (item[i].endIndex > item[i + 1].startIndex) {
          flag = true;
          break;
        }
      }
    });

    if (flag) {
      setTimeErrorMessage("For same selected day time should not overlap");
      setNewError(true);
      setTimeout(() => {
        setTimeErrorMessage("");
        setNewError(false);
      }, 3000);
      return;
    }

    if (!hasError) {
      updateHandler();
      return;
    }
  };

  //handle add availability table
  const handleAddAvailableTable = () => {
    if (
      availabilityDetails[availabilityIndex].selectedDays[0].dayOfWeek
        .length === 0 ||
      availabilityDetails[availabilityIndex].selectedDays[0].startTime
        .length === 0 ||
      availabilityDetails[availabilityIndex].selectedDays[0].endTime.length ===
        0
    ) {
      setDayError(true);
      return;
    }

    const newStartDate = moment(
      availabilityDetails[availabilityDetails?.length - 1]?.endDate
    )
      .add(1, "days")
      .format("YYYY-MM-DD");
    const newEndDate = moment(newStartDate).add(6, "M").format("YYYY-MM-DD");
    dispatch(
      addNewAvailability({
        beginDate: newStartDate,
        endDate: newEndDate,
      })
    );
    setStartDate(newStartDate);
    setendDate(newEndDate); //note: not setEndDate
    setAvailabilityIndex(availabilityDetails?.length);
    // setHasError(false)
  };

  // #region useEffect region
  useEffect(() => {
    if (availabilityIndex >= 0) {
      setStartDate(availabilityDetails[availabilityIndex]?.beginDate);
      setendDate(availabilityDetails[availabilityIndex]?.endDate);
    }
  }, [availabilityIndex]);

  return (
    <>
      <Box className="stepperForm">
        <ValidatorForm onSubmit={handleSubmit}>
          <div className="stepperForm__inner">
            <Grid
              container
              spacing={3}
              mt={"20px"}
              style={{ position: "relative" }}
            >
              {/* Section: + Add Availability Table Button */}
              <Grid item xs={12} className="InsuranceHeader__btn">
                <Button
                  style={{
                    textTransform: "unset",
                    backgroundColor: "rgb(9, 109, 217)",
                    color: "white",
                  }}
                  onClick={() => {
                    handleAddAvailableTable();
                  }}
                >
                  + Add Availability Table
                </Button>
              </Grid>

              <Grid item xs={12}>
                {errorMessage && hasError && (
                  <FormHelperText style={{ color: "#d32f2f" }}>
                    {errorMessage}
                  </FormHelperText>
                )}
              </Grid>

              {/* Section: Availability Table List */}
              <Grid item xs={12} lg={4}>
                <div className="authListBox">
                  {availabilityDetails.map(
                    (item: IAvailabilityDetails, index: number) => {
                      return (
                        <div className="authListBox__item" key={index}>
                          <div
                            className={
                              availabilityIndex === index
                                ? "authListBox__head active"
                                : "authListBox__head"
                            }
                          >
                            <p
                              className="authListBox__title"
                              onClick={() => {
                                setAvailabilityIndex(index);
                                setDayError(false);
                              }}
                            >
                              {item?.beginDate
                                ? moment(item?.beginDate).format("YYYY-MM-DD")
                                : ""}
                              {" - "} <br></br>
                              {item?.endDate
                                ? moment(item?.endDate).format("YYYY-MM-DD")
                                : ""}
                            </p>
                            {availabilityDetails.length === 1 ? null : (
                              <span className="authListBox__removeBtn">
                                <DeleteIcon
                                  onClick={() => {
                                    if (item.id) {
                                      dispatch(
                                        deleteSingleAvailability({
                                          availableId: item.id,
                                        })
                                      );
                                    } else {
                                      dispatch(removeAvailability({}));
                                    }
                                    setAvailabilityIndex(0);
                                  }}
                                />
                              </span>
                            )}
                          </div>
                        </div>
                      );
                    }
                  )}
                </div>
              </Grid>

              <Grid item xs={12} lg={8} mt={3}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                          <DesktopDatePicker
                            className="singleDatePicker"
                            label="Begin Date"
                            inputFormat="YYYY-MM-DD"
                            value={startDate}
                            maxDate={endDate}
                            onChange={handleStartDate}
                            renderInput={(params) => (
                              <TextField
                                onKeyDown={(e) => {
                                  e.preventDefault();
                                }}
                                {...params}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <DesktopDatePicker
                            className="singleDatePicker"
                            label="End Date"
                            inputFormat="YYYY-MM-DD"
                            value={endDate}
                            minDate={startDate}
                            onChange={handleEndDate}
                            renderInput={(params) => (
                              <TextField
                                onKeyDown={(e) => {
                                  e.preventDefault();
                                }}
                                {...params}
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={12}></Grid>
                  <Grid item xs={12} sm={12}>
                    <div className="time-group">
                      <Box className="time-group-select">
                        <label>Day</label>
                      </Box>
                      <span className="time-group__space"></span>
                      <Box className="time-group-select">
                        <label>Time Range</label>
                      </Box>
                      <span className="time-group__space"></span>
                      <Box className="time-group-select">
                        <label></label>
                      </Box>
                      <span className="time-group__space">
                        <label></label>
                      </span>
                    </div>
                  </Grid>
                  {availabilityDetails[availabilityIndex]?.selectedDays.map(
                    (item, index) => {
                      return (
                        <Grid item xs={12} key={index}>
                          <Box className="fromGroup">
                            <Grid container spacing={0}>
                              <Grid item xs={12} sm={12}>
                                <div className="time-group">
                                  <Select
                                    className="fromGroup"
                                    value={item.dayOfWeek}
                                    label="Age"
                                    MenuProps={MenuProps}
                                    onChange={(
                                      e: SelectChangeEvent<string>
                                    ) => {
                                      dispatch(
                                        setDay({
                                          availabilityIndex: availabilityIndex,
                                          index: index,
                                          value: e.target.value,
                                        })
                                      );
                                      setDayError(false);
                                    }}
                                  >
                                    {IWeekDays.map(
                                      (day: string, index: number) => {
                                        return (
                                          <MenuItem key={index} value={day}>
                                            {day}
                                          </MenuItem>
                                        );
                                      }
                                    )}
                                  </Select>
                                  <span className="time-group__space">-</span>
                                  <Box className="time-group-select">
                                    <Select
                                      style={{ width: "100%" }}
                                      // disabled={
                                      //   !availabilityDetails.selectedDays[index]
                                      //     .isAvailable
                                      // }
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      value={item.startTime}
                                      label="Age"
                                      MenuProps={MenuProps}
                                      onChange={(
                                        e: SelectChangeEvent<string>
                                      ) => {
                                        handleFromTime(
                                          e,
                                          index,
                                          IAvailabilityTimesForSchedulesBars
                                        );
                                        setDayError(false);
                                      }}
                                    >
                                      {getValues(0)}
                                    </Select>
                                  </Box>
                                  <span className="time-group__space">-</span>
                                  <Box className="time-group-select">
                                    <Select
                                      style={{ width: "100%" }}
                                      // disabled={
                                      //   !availabilityDetails.selectedDays[index]
                                      //     .isAvailable
                                      // }
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      MenuProps={MenuProps}
                                      value={item.endTime}
                                      label="Age"
                                      onChange={(
                                        e: SelectChangeEvent<string>
                                      ) => {
                                        handleToTime(e, index);
                                        setDayError(false);
                                      }}
                                    >
                                      {getValues(
                                        getIndexFromTime(item.startTime) + 1
                                      )}
                                    </Select>
                                  </Box>
                                  <span className="time-group__space">
                                    <ContentCopyIcon
                                      style={{ paddingTop: "10px" }}
                                      className="removeTimeBtn"
                                      onClick={() =>
                                        duplicateAvailability(index, item)
                                      }
                                    />
                                  </span>
                                  <span className="time-group__space">
                                    <DeleteIcon
                                      style={{ paddingTop: "10px" }}
                                      className="removeTimeBtn"
                                      onClick={() => {
                                        if (
                                          availabilityDetails[availabilityIndex]
                                            .selectedDays.length > 1
                                        ) {
                                          dispatch(
                                            deleteAvailability({
                                              availabilityIndex:
                                                availabilityIndex,
                                              index: index,
                                            })
                                          );
                                        } else {
                                          dispatch(
                                            setDay({
                                              availabilityIndex:
                                                availabilityIndex,
                                              index: index,
                                              value: "",
                                            })
                                          );
                                          dispatch(
                                            setFromTime({
                                              availabilityIndex:
                                                availabilityIndex,
                                              index: index,
                                              value: "",
                                            })
                                          );
                                          dispatch(
                                            setToTime({
                                              availabilityIndex:
                                                availabilityIndex,
                                              index: index,
                                              value: "",
                                            })
                                          );
                                        }
                                      }}
                                    />
                                  </span>
                                </div>
                              </Grid>
                            </Grid>
                          </Box>
                        </Grid>
                      );
                    }
                  )}
                  {timeErrorMessage && (
                    <FormHelperText
                      style={{ color: "#d32f2f", paddingLeft: "26px" }}
                    >
                      {timeErrorMessage}
                    </FormHelperText>
                  )}
                </Grid>
                {dayError && (
                  <FormHelperText style={{ color: "#d32f2f" }}>
                    Please Select All Fields
                  </FormHelperText>
                )}
              </Grid>
            </Grid>
          </div>
          <div className="largeModel__footer">
            <Button className="button" type="submit">
              Save Changes
            </Button>
          </div>
        </ValidatorForm>
      </Box>
    </>
  );
};

export default AvailabilityForm;
