import { Grid, Typography } from "@mui/material";
import MessageDetails from "./message-details/MessageDetails";

const Message = () => {
  return (
    <div className="pageTemplate mapDetailsTemplate">
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <div className="listingTable">
            <div className="listingTable__header">
              <Typography
                className="listingTable__title"
                gutterBottom
                variant="h6"
              >
                Message Center
              </Typography>
            </div>
          </div>
        </Grid>
        <Grid item xs={12}>
          <MessageDetails />
        </Grid>
      </Grid>
    </div>
  );
};

export default Message;
