import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormHelperText,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
  tableCellClasses,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import UpgradeIcon from "@mui/icons-material/Upgrade";
import FileUpload from "react-material-file-upload";
import { setResponseValue } from "../../redux/features/api-response";
import { RootState, useAppDispatch, } from "../../redux/store";
import providerService from "../../service/provider.service";
import { useUser } from "@descope/react-sdk";
import {
  deleteProviderFilesById,
  getProviderFilesById,
} from "../../redux/features/providerSlice";
import { useSelector } from "react-redux";
import { FileCategory, IEmployeePortalData, IFiles } from "../../type/providerDetails";
import "./document.scss";
import { ReactComponent as PDF } from "../../assets/images/images-svg/pdfIcon.svg";
import { Close } from "@mui/icons-material";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    whiteSpace: "nowrap",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    verticalAlign: "baseline",
    whiteSpace: "nowrap",
  },
  padding: "5px 20px",
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#ECF6FF",
    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.15)",
    height: "40px",
  },

  "&:last-child td, &:last-child th": {
    border: 0,
  },
  height: "50px",
}));
const StyledTable = styled(Table)(({ theme }) => ({
  [`& .${tableCellClasses.root}`]: {
    borderBottom: "none",
  },
}));

const Document = () => {
  //#region variable region
  const dispatch = useAppDispatch();

  //stores files details
  const [files, setFiles] = useState<File[]>([]);

  const { user } = useUser();

  //stores provider details
  const providerDetails = useSelector<RootState, IEmployeePortalData>(
    (state) => state.providerSlice.providerProfile
  );

  //stores document files details
  const providerFiles = useSelector<RootState, IFiles[]>(
    (state) => state.providerSlice.providerProfile.providerFiles
  );
  const visibleFiles = useSelector<RootState, IFiles[]>(
    (state) => providerFiles?.filter((item) => item.category === FileCategory.Onboarding ||
      item.category === FileCategory.Credentialing)
  );

  //files modal controller
  const [filesModal, setFilesModal] = useState<boolean>(false);

  //stores provider id
  // eslint-disable-next-line
  const [providerId, setProviderId] = useState(
    providerDetails?.employmentDetails?.id
  );

  //handle error  message
  const [hasError, setHasError] = useState<boolean>(false);

  //delete popup handler
  const [deletePopup, setDeletePopup] = useState<boolean>(false);

  //store id for delete file
  const [deleteFileId, setDeleteFileId] = useState<string>("");

  //#endregion

  //#region mathods region

  //handle submit function
  const onSubmit = async () => {
    if (files.length === 0) {
      setHasError(true);
      return;
    }
    setFilesModal(false);
    try {
      dispatch(setResponseValue({ name: "pending", value: true }));
      const { status, message } = await providerService.postproviderFiles(
        "OnBoarding",
        "Provider",
        "Provider",
        providerId,
        files
      );

      if (status) {
        // setFileUploaded(true);
        setFiles([]);
        dispatch(getProviderFilesById({ providerId }));
        setFiles([]);
        // dispatch(getChildDetails({ id: ChildData?.id }));
        // dispatch(setClientFilesDataChange({}));
        dispatch(setResponseValue({ name: "success", value: true }));
        dispatch(setResponseValue({ name: "message", value: message }));
      }
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(setResponseValue({ name: "message", value: e?.message }));
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  };

  //handle to download pdf
  const onClickPdf = (item: any) => {
    const link = document.createElement("a");
    link.href = item.fileUrl;
    link.download = item.fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  //#endregion

  //#region useEffect region

  //used for fetching provider data
  useEffect(() => {
    if (user) {
      dispatch(getProviderFilesById({ providerId: providerId }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [providerFiles?.length]);

  //#endregion

  return (
    <>
      <Box p={"25px"}>
        <div>
          <Typography
            gutterBottom
            variant="h6"
            component="div"
            fontWeight="bold"
            className="listingTable__title"
          >
            Documents
          </Typography>
        </div>

        <TableContainer className="tableResponsive">
          <StyledTable
            sx={{
              minWidth: 700,
              borderCollapse: "separate",
              cursor: "pointer",
            }}
            aria-label="customized table"
          >
            <TableHead>
              <TableRow>
                <StyledTableCell>File Name</StyledTableCell>
                <StyledTableCell>File Category</StyledTableCell>
                <StyledTableCell>Upload Time</StyledTableCell>
                <StyledTableCell>Uploaded By</StyledTableCell>
                <StyledTableCell align="right" style={{ color: "#096DD9" }}>
                  <button
                    className="uploadCoustomBtn"
                    onClick={() => {
                      setHasError(false);
                      setFilesModal(true);
                    }}
                  >
                    <UpgradeIcon />
                    Upload File
                  </button>
                </StyledTableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {/* <StyledTableRow style={{ borderRadius: 20 }}> */}
              {/* <StyledTableCell>{"Insurance Card (front)"}</StyledTableCell>
              <StyledTableCell>{"10/31/2023"}</StyledTableCell>
              <StyledTableCell align="right">
                <div className="tableIconBtn">
                  <DeleteIcon />
                </div>
              </StyledTableCell> */}

              {visibleFiles?.map((item, index) => (
                <StyledTableRow style={{ borderRadius: 20 }} key={index}>
                  <StyledTableCell
                    component="th"
                    onClick={() => {
                      onClickPdf(item);
                    }}
                    style={{ width: "50%", whiteSpace: "unset" }}
                  >
                    <div className="userList-name">
                      <span style={{ marginLeft: 10 }}>
                        <div className="fileListItem">
                          <div
                            className="fileListItem__heading"
                            style={{ wordBreak: "break-all" }}
                          >
                            <PDF height={"18px"} width={"18px"} />
                            <Typography className="fileListItem__name">
                              {item.fileName}
                            </Typography>
                          </div>
                        </div>
                      </span>
                    </div>
                  </StyledTableCell>
                  <StyledTableCell>
                    {item.category}
                  </StyledTableCell>
                  <StyledTableCell>
                    {new Date(item.updatedAt).toLocaleString()}
                  </StyledTableCell>
                  <StyledTableCell>
                    {item.uploadedBy}
                    {/* {providerDetails.employmentDetails.firstName} {" "}
                    {providerDetails.employmentDetails.lastName} */}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    <div className="tableIconBtn">
                      <DeleteIcon
                        onClick={() => {
                          setDeletePopup(true);
                          // dispatch(deleteProviderFilesById({ fileId: item.id }));
                          setDeleteFileId(item.id);
                        }}
                      />
                    </div>
                  </StyledTableCell>
                </StyledTableRow>
              ))}

              {/* </StyledTableRow> */}
            </TableBody>
          </StyledTable>
        </TableContainer>
      </Box>
      {filesModal && (
        <Dialog open={true} className="largeModel">
          <Grid>
            <Box className="largeModel__head">
              <Typography variant="h5" className="largeModel__title">
                Upload Files
              </Typography>
              <Button
                className="largeModel__closeBtn"
                onClick={() => {
                  setHasError(false);
                  setFilesModal(false);
                }}
              >
                <Close />
              </Button>
            </Box>
            <Box className="largeModel__body p-30px">
              <Grid container spacing={3} mb={"30px"}>
                <Grid item lg={6} xs={12}>
                  <Box className="fromGroup">
                    <Grid container spacing={3}>
                      <Grid item xs={12} className="fileUploadBlock">
                        <FileUpload
                          multiple
                          value={files}
                          onChange={(e: File[]) => {
                            setFiles([...files, ...e]);
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <div className="uploadProfile__img selectedIn-fileBox">
                          {files &&
                            files.map((file, index) => {
                              return (
                                <div className="selectedIn-file">
                                  <h1>{file.name}</h1>
                                  <span>
                                    <DeleteIcon
                                      onClick={() => {
                                        setFiles(
                                          files.filter(
                                            (_, fileIndex) =>
                                              fileIndex !== index
                                          )
                                        );
                                      }}
                                    />
                                  </span>
                                </div>
                              );
                            })}
                        </div>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
              {hasError && files.length === 0 && (
                <FormHelperText style={{ color: "#d32f2f" }}>
                  Please select files first
                </FormHelperText>
              )}
            </Box>
            <Box className="stepperForm__footer">
              <Button
                type="submit"
                color="inherit"
                className="border-button"
                onClick={() => {
                  setHasError(false);
                  setFilesModal(false);
                }}
              >
                Cancel
              </Button>
              <div></div>
              <Box sx={{ flex: "1 1 auto" }} />
              <Button className="button" onClick={onSubmit}>
                Save
              </Button>
            </Box>
          </Grid>
        </Dialog>
      )}

      <Dialog open={deletePopup} className="smallModel">
        <div>
          <DialogContent>Do you want to delete this file?</DialogContent>
          <DialogActions>
            <Button
              className="border-button"
              variant="contained"
              onClick={() => setDeletePopup(false)}
              color="secondary"
            >
              No
            </Button>
            <Button
              className="button"
              variant="contained"
              onClick={() => {
                dispatch(deleteProviderFilesById({ fileId: deleteFileId }));
                setDeletePopup(false);
              }}
            >
              Yes
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </>
  );
};

export default Document;
