import { AllUserMessage, IComments, IMessage } from "../type/message";

export const comments: IComments = {
  createdAt: "",
  fromUser: {
    firstName: "",
    lastName: "",
    email: "",
    profilePictureUrl: "",
  },
  fromName: "",
  comment: "",
};

export const profile = {
  url: "",
  key: "",
  containerName: "",
  fileName: "",
  fileId: "",
};

export const allUserMessage: AllUserMessage = {
  id: " ",
  subject: " ",
  message: " ",
  comments: [],
  entityId: " ",
  whoCanView: [],
  // active: false,,
  seenUserId: [],
  type: " ",
  fromUser: {
    firstName: "",
    lastName: "",
    email: "",
    profilePictureUrl: "",
  },
  createdOn: " ",
  updatedOn: " ",
  entityType: "",
  files: [],
};

export const initialState: IMessage = {
  allMessageData: {
    allUserMessage: [],
    externalCount: 0,
    internalCount: 0,
    UpdateCount: 0,
  },
  openedMessage: null,
  translatedMessage: null,
};

export const sortData = (Comments: AllUserMessage[]) => {
  let data = Comments.sort((a, b) => {
    return (new Date(b.createdOn) as any) - (new Date(a.createdOn) as any);
  });
  return data;
};

export const isHtml = RegExp.prototype.test.bind(/(<([^>]+)>)/i);

export function htmlToPlainText(html: string) {
  // Remove HTML tags using a regular expression
  const plainText = html.replace(/<\/?[^>]+(>|$)/g, " ");
  return plainText;
}

export function getFirstLineHtml(htmlString: string) {
  let firstLine = htmlString.split("\n")[0];
  if (firstLine?.length > 0) {
    firstLine = firstLine.slice(0, 120) + "...";
    firstLine = htmlToPlainText(firstLine);
  }
  return firstLine;
}