import { Descope } from "@descope/react-sdk";
import React from "react";
import { useNavigate } from "react-router-dom";

const Login: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Descope
      flowId="otp-sign-in-employee"
      onSuccess={() => navigate("/")}
      onError={(e) => console.log("Could not log in!", e)}
    />
  );
};

export default Login;
