import { Box, Grid } from "@mui/material";
import ClientList from "./ClientList";
import EmployeeList from "./EmployeeList";
import { Dispatch, SetStateAction } from "react";

export interface UserListProps {
  isMobileView: boolean;
  setIsMobileView?: Dispatch<SetStateAction<boolean>>;
  setSelectedUser: React.Dispatch<
    React.SetStateAction<{
      id: string;
      name: string;
      type: "Provider" | "Client";
    } | null>
  >;
  selectedUser: {
    id: string;
    name: string;
  } | null;
}
export interface MessageHeaderProps extends UserListProps {
  selectedTab: string;
  setSelectedTab: Dispatch<SetStateAction<string>>;
}

const UserList: React.FC<MessageHeaderProps> = ({
  selectedUser,
  isMobileView,
  selectedTab,
  setSelectedUser,
  setIsMobileView,
  setSelectedTab,
}) => {
  return (
    <Grid item xs={16} md={4}>
      <Box display={"flex"}>
        <ul className="innerTabs">
          <li className="innerTabs__item">
            <button
              onClick={() => {
                setSelectedTab("Clients");
              }}
              className={selectedTab === "Clients" ? "active" : ""}
            >
              Clients
            </button>
          </li>
          <li className="innerTabs__item">
            <button
              onClick={() => {
                setSelectedTab("Employees");
              }}
              className={selectedTab === "Employees" ? "active" : ""}
            >
              Employees
            </button>
          </li>
        </ul>
      </Box>
      <div>
        {selectedTab === "Clients" && (
          <ClientList
            selectedUser={selectedUser}
            setSelectedUser={setSelectedUser}
            isMobileView={isMobileView}
            setIsMobileView={setIsMobileView}
          />
        )}
        {selectedTab === "Employees" && (
          <EmployeeList
            selectedUser={selectedUser}
            setSelectedUser={setSelectedUser}
            isMobileView={isMobileView}
          />
        )}
      </div>
    </Grid>
  );
};

export default UserList;
