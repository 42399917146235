import { configureStore } from "@reduxjs/toolkit";
import responseSlice from "./features/api-response";
import providerSlice from "./features/providerSlice";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import ListDataSlice from "./features/listDataSlice";
import schedulesFilterSlice from "./features/schedule/schedule-filter-slice";
import providerOnboardingFormSlice from "./features/providerForm/form-slice";
import organizationSlice from "./features/organization/organization-slice";
import availabilitySlice from "./features/availability/availability-slice";
import messageSlice from "./features/message/message-slice";
import taskManagerSlice from "./features/taskManager/taskManagerSlice";
import sessionSlice from "./features/session/sessionSlice";

export const store = configureStore({
  reducer: {
    responseSlice: responseSlice.reducer,
    providerSlice: providerSlice.reducer,
    listDataSlice: ListDataSlice.reducer,
    schedulesFilterSlice: schedulesFilterSlice.reducer,
    providerOnboardingFormSlice: providerOnboardingFormSlice.reducer,
    organizationSlice: organizationSlice.reducer,
    availabilitySlice: availabilitySlice.reducer,
    messageSlice: messageSlice.reducer,
    taskManagerSlice: taskManagerSlice.reducer,
    sessionSlice: sessionSlice.reducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
