import ClientImg from "../../../assets/images/images-svg/client-large.svg";
import { useEffect } from "react";
import { useAppSelector } from "../../../redux/store";
import { UserListProps } from "./UserList";

const ClientList: React.FC<UserListProps> = ({
  selectedUser,
  setSelectedUser,
  isMobileView,
  setIsMobileView,
}) => {
  //user from Descope
  const clientList = useAppSelector((state) => state.listDataSlice.clientList);

  //region useEffect
  useEffect(() => {
    if (!setIsMobileView) {
      return;
    }
    window.addEventListener("resize", () => {
      const width = window.innerWidth;
      if (width <= 886) {
        setIsMobileView(true);
      } else {
        setIsMobileView(false);
      }
    });
    if (window.innerWidth <= 886 && !isMobileView) {
      setIsMobileView(true);
    }
  }, []);

  useEffect(() => {
    if (clientList[0] && window?.innerWidth >= 886) {
      setSelectedUser({
        id: clientList[0]?.clientBasicDetails?.id,
        name: `${clientList[0]?.clientBasicDetails?.childFirstName} ${clientList[0]?.clientBasicDetails?.childLastName}`,
        type: "Client",
      });
    } else {
      setSelectedUser(null);
    }
  }, [clientList]);

  return (
    <div className="mapUserList">
      {clientList.map((item) => {
        return (
          <div
            className={
              selectedUser?.id === item?.clientBasicDetails?.id
                ? "fromGroup-chip-active"
                : "userMapCart "
            }
            key={item?.clientBasicDetails?.id}
            onClick={() =>
              setSelectedUser({
                id: item?.clientBasicDetails?.id,
                name: `${item?.clientBasicDetails?.childFirstName} ${item?.clientBasicDetails?.childLastName}`,
                type: "Client",
              })
            }
          >
            <div className="cardLevelBlock">
              <table>
                <thead>
                  <tr>
                    <th
                      style={{
                        display: "flex",
                        gap: "10px",
                        justifyContent: "space-between",
                        marginRight: "16px",
                        alignItems: "center",
                      }}
                    >
                      {item.clientBasicDetails.messageCount > 0 && (
                        <span
                          style={{
                            width: "10px",
                            borderRadius: "50%",
                            background: "#096dd9",
                            padding: "5px",
                          }}
                        />
                      )}
                      <span className="userMapCart__img">
                        <img
                          src={
                            item?.clientBasicDetails?.clientProfile?.url ||
                            ClientImg
                          }
                          className="userSmallCircle"
                          alt=""
                        />
                      </span>
                    </th>
                    <th>
                      <div className="userMapCart__name">
                        {item?.clientBasicDetails?.childFirstName}
                        {item?.clientBasicDetails?.childLastName}
                      </div>
                    </th>
                  </tr>
                </thead>
              </table>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ClientList;
