import { createSlice } from "@reduxjs/toolkit";
import { ISetavailability } from "../../../type/availability";
import { availability } from "../../../constants/availability";

const initialState: ISetavailability = {
    ...availability
}

const availabilitySlice = createSlice({
  name: "availabilitySlice",
  initialState: initialState,
  reducers: {
    setSelectedAvailabiltyValues: (state, action) => {
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
    },
  },
});

export const { setSelectedAvailabiltyValues } = availabilitySlice.actions;

export default availabilitySlice;