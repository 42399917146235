import { createSlice } from "@reduxjs/toolkit";
import { scheduleFilters } from "../../../constants/schedule";
import { IScheduleFilters } from "../../../type/schedule";


const initialState: IScheduleFilters = {
  ...scheduleFilters,
};

const schedulesFilterSlice = createSlice({
  name: "filter",
  initialState: initialState,
  reducers: {
    setSchedulesFilterValues: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    clearSchedulesFilterValues: (state, action) => {
    
      return {
        ...action.payload
      };
    },
  },
});

export const { setSchedulesFilterValues, clearSchedulesFilterValues } =
  schedulesFilterSlice.actions;

export default schedulesFilterSlice;
