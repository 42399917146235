//CTO Review: subset of definitions, same as ones in Admin App, mneed to merge.

export const Billable: string[] = [
  "Assessment",
  "Direct Therapy",
  "Parent Training",
  "Protocol modification",
];

export const NonBillable:string[] = [
    "Create Materials",
    "Drive Time",
    "Lunch Break"
]

//comapare dates
export const formattedDate = (item: any): string => {
  let FullDate = item.split("-");
  let Year = parseInt(FullDate[0]);
  let Month = parseInt(FullDate[1]);
  let Date = parseInt(FullDate[2]);

  return ` ${Year}${"/"}${Month}${"/"}${Date}`;
};