import axios from "../axios/axios";

const getClientDetails = async () => {
  try {
    const { data } = await axios.get(`/employee/client`);
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

const getClientsByEmployee = async () => {
  try {
    const data = await axios.get(`/employee/client`);
    return data;
  } catch (error: any) {
    throw error?.response?.data;
  }
};

const ClientService = {
  getClientDetails,
  getClientsByEmployee
};

export default ClientService;
