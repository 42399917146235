import axios from "../axios/axios";

//get current User Task
export const getMyTasks = async () => {
  try {
    const { data } = await axios.get("/task");
    return data;
  } catch (error: any) {
    throw error?.response?.data;
  }
};

export const getAllTaskType = async () => {
  try {
    const { data } = await axios.get(`/datacatalog/Task Type`);
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

//
export const patchTaskById = async (
  id: string,
  payload: { type: string; value: string }
) => {
  try {
    const { data } = await axios.patch(`/task/${id}`, payload);
    return data;
  } catch (error: any) {
    throw error?.response?.data;
  }
};

//updates Task by Id
export const updateTaskById = async (id: string, payloadData: any) => {
  try {
    const { data } = await axios.put(`/task/${id}`, payloadData);
    return data;
  } catch (error: any) {
    throw error?.response?.data;
  }
};

const TaskManagerService = {
  getMyTasks,
  patchTaskById,
  updateTaskById,
  getAllTaskType
};
export default TaskManagerService;
