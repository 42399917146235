import moment from "moment";



// call this function, passing-in your date
export const dateToFromNowDaily = (date: any): string => {
  //get localDtm
  
  
  //var localDtm = moment(date).utcOffset(0, true).format();
  var localDtm = moment(new Date(date).toLocaleString()).format();
  var localTime = moment(date).format("LT");
  
  // get from-now for this date
  var fromNow = moment(localDtm).fromNow();



  // ensure the date is displayed with today and yesterday
  let value = `${moment(date).calendar(null, {
    // when the date is closer, specify custom values
    lastWeek: "[Last] dddd",
    lastDay: "[Yesterday]",
    sameDay: "[Today]",
    nextDay: "[Tomorrow]",
    nextWeek: "dddd",
    // when the date is further away, use from-now functionality
    sameElse: function () {
      return "[" + fromNow + "]";
    },
  })} ${localTime}`;



  return value;
};