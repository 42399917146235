import axios from "../axios/axios";

export const getOrgInfo = async () => {
  try {
    //call api
    const { data } = await axios.get(`/organization`);
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

const OrganizationDetails = {
  getOrgInfo,
};

export default OrganizationDetails;