import { createSlice } from "@reduxjs/toolkit";
import { IResponse } from "../../type/api-response";

const initialState: IResponse = {
  success: false,
  error: false,
  pending: false,
  message: "",
};

const responseSlice = createSlice({
  name: "responseSlice",
  initialState: initialState,
  reducers: {
    setResponseValue: (state, action) => {
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
    },
  },
});

export const {setResponseValue} = responseSlice.actions;
export default responseSlice;