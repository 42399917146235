import { useState } from "react";
import { Box, Button, Grid } from "@mui/material";
import "./message-details.scss";
import UserList from "./UserList";
import MessageField from "./MessageField";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

const MessageDetails: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState("Clients");
  const [isMobileView, setIsMobileView] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<{
    id: string;
    name: string;
    type: "Provider" | "Client";
  } | null>(null);

  return (
    <Box sx={{ flexGrow: 1 }} className="mapWrapper">
      <Grid container spacing={2} columns={16}>
        {((selectedUser === null && isMobileView) || !isMobileView) && (
          <UserList
            setSelectedUser={setSelectedUser}
            selectedUser={selectedUser}
            isMobileView={isMobileView}
            setIsMobileView={setIsMobileView}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
          />
        )}

        {selectedUser !== null && isMobileView && (
          <>
            <div style={{ marginTop: "10px" }}>
              <Button
                onClick={() => setSelectedUser(null)}
                className="backBtn"
                startIcon={<KeyboardBackspaceIcon />}
                style={{ marginBottom: 0 }}
              >
                Back
              </Button>
            </div>
          </>
        )}
        {selectedUser && <MessageField selectedUser={selectedUser} />}
      </Grid>
    </Box>
  );
};

export default MessageDetails;
