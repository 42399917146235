import React from "react";
import { useSession } from "@descope/react-sdk";
import { Navigate } from "react-router-dom";

const PublicRoute: React.FC<{ children: any }> = ({ children }) => {
  const { isAuthenticated } = useSession();
  const path = localStorage.getItem("path");
  return isAuthenticated && path ? (
    <Navigate to={path} />
  ) : isAuthenticated && !path ? (
    <Navigate to={"/"} />
  ) : (
    children
  );
};
export default PublicRoute;
