import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextareaAutosize,
} from "@mui/material";
import ReactHtmlParser from "html-react-parser";
import { AllUserMessage, IMessage } from "../../../type/message";
import { dateToFromNowDaily } from "../../../helper/reverselist";
import ClientImg from "../../../assets/images/images-svg/client-large.svg";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  RootState,
  useAppDispatch,
  useAppSelector,
} from "../../../redux/store";
import { IEmployeePortalData } from "../../../type/providerDetails";
import { useUser } from "@descope/react-sdk";
import { ReactComponent as Download } from "../../../assets/images/images-svg/Download.svg";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import ImageIcon from "@mui/icons-material/Image";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  PostSubComments,
  UpdateMessageStatusById,
  openMessage,
  setTranslation,
} from "../../../redux/features/message/message-slice";
import { InternalMessageProps } from "./InternalMessage";
import { getFirstLineHtml, isHtml, sortData } from "../../../constants/message";
import MessageFormatter from "./MessageFormatter";

const OpenedMessage: React.FC<{
  selectedUser: {
    id: string;
    name: string;
    type: "Client" | "Provider";
  };
}> = ({ selectedUser }) => {
  const dispatch = useAppDispatch();
  const [internalComment, setInternalComment] = useState<string>("");
  const [subCommentFiles, setSubCommentFiles] = useState<any[]>([]);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const {
    user: { userId },
  } = useUser();

  const {openedMessage,translatedMessage} = useSelector<RootState, IMessage>(
    (state) => state.messageSlice
  );

  const currentMessage = translatedMessage
  ? translatedMessage
  : openedMessage;

  const providerDetails = useSelector<RootState, IEmployeePortalData>(
    (state) => state.providerSlice.providerProfile
  );

  let senderName = `${providerDetails.employmentDetails.firstName}${" "}${
    providerDetails.employmentDetails.lastName
  }`;

  if (!openedMessage) return null;

  const handleSubComments = async () => {
    try {
      dispatch(
        PostSubComments({
          messageId: openedMessage.id,
          entityId: selectedUser.id,
          comment: internalComment,
          entityType: selectedUser.type,
          files: subCommentFiles,
        })
      );
    } catch (error: any) {
      console.log(error);
    } finally {
      setInternalComment("");
      setSubCommentFiles([]);
    }
  };

  //handles drop files
  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const files = Array.from(e.dataTransfer.files);
    const totalFiles = subCommentFiles.length + files.length;

    if (totalFiles > 5) {
      setErrorMessage("You can only upload up to 5 files.");
      return;
    } else {
      setErrorMessage("");
    }

    const oversizedFiles = files.filter((file) => file.size > 5 * 1024 * 1024); // 5 MB in bytes
    if (oversizedFiles.length > 0) {
      setErrorMessage("File size exceeds the limit of 5 MB.");
      return;
    } else {
      setErrorMessage("");
    }

    setSubCommentFiles([...subCommentFiles, ...files]);
  };

  //handles dragover files
  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  return (
    <>
      <Grid
        className={"messageInternal-block"}
        item
        sx={{ paddingTop: "5px" }}
        xs={16}
        key={openedMessage.id}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <span
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {!openedMessage.seenUserId.includes(userId) && (
              <span className="active" />
            )}
            <img
              src={openedMessage?.fromUser?.profilePictureUrl || ClientImg}
              height={35}
              alt=""
            />
            <h5 style={{ margin: "0px 10px 5px 10px" }}>{senderName}</h5>
          </span>
          <h5>
            <span>{dateToFromNowDaily(currentMessage?.createdOn)}</span>
          </h5>
        </Box>
        <Box>
          <div className="messageTicket-number">
            <strong>Subject: {currentMessage?.subject}</strong>
          </div>
        </Box>
        <Box sx={{ maxHeight: "350px", overflowY: "auto" }}>
          <MessageFormatter message={currentMessage?.message!} />
        </Box>
        <div
          style={{
            textDecoration: "none",
            display: "flex",
            gap: "10px",
          }}
        >
          {currentMessage?.files?.map((file: any) => {
            return (
              <span>
                <a
                  href={`${file?.url}`}
                  style={{
                    textDecoration: "none",
                    color: "black",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {["JPG", "JPEG", "PNG"].includes(
                    file?.url?.split(".").pop()?.toUpperCase() || ""
                  ) ? (
                    <ImageIcon style={{ color: "#096dd9" }} />
                  ) : (
                    <InsertDriveFileIcon style={{ color: "#096dd9" }} />
                  )}
                  <span
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      maxWidth: "150px",
                    }}
                    title={file.fileName}
                  >
                    {file.name || file.fileName}
                  </span>
                  <IconButton style={{ background: "transparent" }}>
                    <Download />
                  </IconButton>
                </a>
              </span>
            );
          })}
        </div>
      </Grid>
      <Grid item xs={16} md={16}>
        <Grid
          className="commentForm-lavel"
          item
          xs={16}
          md={12}
          style={{ marginTop: "50px" }}
        >
          Follow-Ups
        </Grid>
        {/* <span style={{ color: "#d32f2f" }}>{CommentError}</span> */}
        <span style={{ color: "#d32f2f" }}>{errorMessage}</span>
        <Box className="commentForm">
          <div
            onDrop={(e) => handleDrop(e)}
            onDragOver={(e) => handleDragOver(e)}
            style={{ width: "100%" }}
          >
            <TextareaAutosize
              value={internalComment}
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                setInternalComment(e.target.value);
              }}
              placeholder="Reply here..."
              minRows={2}
            />
            <Button variant="contained" onClick={handleSubComments}>
              Add Follow-Up
            </Button>
          </div>
          <div style={{ display: "flex", flexWrap: "wrap", width: "100%" }}>
            {subCommentFiles &&
              subCommentFiles.map((file, index) => {
                return (
                  <div className="selectedIn-file">
                    {file.name}
                    <span>
                      <DeleteIcon
                        onClick={() => {
                          setSubCommentFiles(
                            subCommentFiles.filter(
                              (_, fileIndex) => fileIndex !== index
                            )
                          );
                        }}
                      />
                    </span>
                  </div>
                );
              })}
          </div>
        </Box>
      </Grid>
      {currentMessage?.comments?.map((item: any) => {
        return (
          <Box className="commentListItem generated" key={item.id}>
            <Box className="compose-block">
              <span className="commentListItem__user-img">
                <img
                  src={item?.fromUser?.profilePictureUrl || ClientImg}
                  className="userSmallCircle"
                  alt=""
                />
              </span>
              <div className="commentListItem__user-info">
                <div className="compose-user-heading">
                  <h5 className="compose-user-name">
                    {item.fromUser?.firstName
                      ? item.fromUser?.firstName + " " + item.fromUser?.lastName
                      : item.fromUser?.email}
                  </h5>
                  <h6 className="compose-user-time">
                    <span>{dateToFromNowDaily(item?.createdAt)}</span>
                  </h6>
                </div>

                <div
                  className="compose-user-comment"
                  style={{ whiteSpace: "pre-line" }}
                >
                  {item.comment}
                </div>
                <div
                  style={{
                    textDecoration: "none",
                    display: "flex",
                    gap: "10px",
                  }}
                >
                  {item?.files?.map((file: any) => {
                    return (
                      <span>
                        <a
                          href={`${file?.url}`}
                          style={{
                            textDecoration: "none",
                            color: "black",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {["JPG", "JPEG", "PNG"].includes(
                            file?.url?.split(".").pop()?.toUpperCase() || ""
                          ) ? (
                            <ImageIcon style={{ color: "#096dd9" }} />
                          ) : (
                            <InsertDriveFileIcon style={{ color: "#096dd9" }} />
                          )}
                          <span
                            style={{
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              maxWidth: "150px",
                            }}
                            title={file.fileName}
                          >
                            {file.name || file.fileName}
                          </span>
                          <IconButton style={{ background: "transparent" }}>
                            <Download />
                          </IconButton>
                        </a>
                      </span>
                    );
                  })}
                </div>
              </div>
            </Box>
          </Box>
        );
      })}
    </>
  );
};

interface ExternalMessageComponentProps {
  handleMessageOpen: () => void;
  message: AllUserMessage;
}
const ExternalMessageComponent: React.FC<ExternalMessageComponentProps> = ({
  handleMessageOpen,
  message: item,
}) => {
  const {
    user: { userId },
  } = useUser();

  const providerDetails = useSelector<RootState, IEmployeePortalData>(
    (state) => state.providerSlice.providerProfile
  );

  let senderName = `${providerDetails.employmentDetails.firstName}${" "}${
    providerDetails.employmentDetails.lastName
  }`;

  return (
    <Grid
      className={
        !item.seenUserId.includes(userId)
          ? "messageInternal-block light-blue-bg"
          : "messageInternal-block"
      }
      item
      sx={{ paddingTop: "5px" }}
      xs={16}
      key={item.id}
      onClick={handleMessageOpen}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <span
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {!item.seenUserId.includes(userId) && <span className="active" />}
          <img
            src={item?.fromUser?.profilePictureUrl || ClientImg}
            height={35}
            alt=""
          />
          <h5 style={{ margin: "0px 10px 5px 10px" }}>{senderName}</h5>
        </span>
        <h5>
          <span>{dateToFromNowDaily(item?.createdOn)}</span>
        </h5>
      </Box>
      <Box>
        <div className="messageTicket-number">
          <strong>Subject: {item?.subject}</strong>
        </div>
      </Box>
      <Box className="message-listingBlock">
        {isHtml(item.message) ? (
          <>
          <div className="">
            <span
              style={{ cursor: "pointer" }}
            >
              {getFirstLineHtml(item.message)}
            </span>
            <p></p>
            <span style={{ color: "#096dd9", cursor: "pointer" }}>
              {"See More Details..."}
            </span>
        </div>
          </>
        ) : (
          item.message
        )}
      </Box>
      <div
        style={{
          textDecoration: "none",
          display: "flex",
          gap: "10px",
        }}
      >
        {item?.files?.map((file: any) => {
          return (
            <span>
              <a
                href={`${file?.url}`}
                style={{
                  textDecoration: "none",
                  color: "black",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {["JPG", "JPEG", "PNG"].includes(
                  file?.url?.split(".").pop()?.toUpperCase() || ""
                ) ? (
                  <ImageIcon style={{ color: "#096dd9" }} />
                ) : (
                  <InsertDriveFileIcon style={{ color: "#096dd9" }} />
                )}
                <span
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    maxWidth: "150px",
                  }}
                  title={file.fileName}
                >
                  {file.name || file.fileName}
                </span>
                <IconButton style={{ background: "transparent" }}>
                  <Download />
                </IconButton>
              </a>
            </span>
          );
        })}
      </div>
    </Grid>
  );
};

const ExternalMessage: React.FC<InternalMessageProps> = ({
  allMessages,
  selectedUser,
}) => {
  const dispatch = useAppDispatch();

  //#region variables region
  const openedMessage = useAppSelector(
    (state) => state.messageSlice.openedMessage
  );

  //user from Descope
  const {
    user: { userId },
  } = useUser();

  useEffect(() => {
    dispatch(openMessage(null));
    dispatch(setTranslation(null));
  }, [selectedUser.id]);

  if (allMessages.length === 0) return <h4>No External Message</h4>;

  if (openedMessage) return <OpenedMessage selectedUser={selectedUser} />;

  const handleMessageOpen = (item: AllUserMessage) => {
    if (!item.seenUserId.includes(userId)) {
      dispatch(
        UpdateMessageStatusById({
          id: [item.id],
          userId,
          type: "externalCount",
        })
      );
    }
    dispatch(
      openMessage({ ...item, seenUserId: [...item.seenUserId, userId] })
    );
  };

  return (
    <>
      {sortData(allMessages).map((item) => (
        <ExternalMessageComponent
          message={item}
          handleMessageOpen={handleMessageOpen.bind(null, item)}
          key={item.id}
        />
      ))}
    </>
  );
};

export default ExternalMessage;
