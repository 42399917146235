import { AppUrls } from "../utils/AppUrls";

export interface Tabs {
  tabName: string;
  id: string;
  url: string;
}

//header tabs
export const HeadersTabs: Tabs[] = [
  {
    tabName: "Home",
    id: "1",
    url: AppUrls.Client.Home,
  },
  {
    tabName: "Schedules",
    id: "2",
    url: AppUrls.Client.Schedule,
  },
  {
    tabName: "Sessions",
    id: "3",
    url: AppUrls.Client.Sessions,
  },
  {
    tabName: "Documents",
    id: "4",
    url: AppUrls.Client.Document,
  },
  {
    tabName: "Tasks",
    id: "5",
    url: AppUrls.Client.Tasks,
  },
  {
    tabName: "Messages",
    id: "6",
    url: AppUrls.Client.Messages,
  },
];

//clients Tabs
export const ClientTabs: Tabs[] = [
  {
    tabName: "Summary",
    id: "1",
    url: "",
  },
  {
    tabName: "Schedules",
    id: "2",
    url: "",
  },
  {
    tabName: "Files",
    id: "3",
    url: "",
  },
];
