import { Internationalization } from "@syncfusion/ej2-base";
import {
  Agenda,
  Day,
  DragAndDrop,
  EventRenderedArgs,
  Inject,
  Month,
  PopupOpenEventArgs,
  RenderCellEventArgs,
  Resize,
  ResourceDetails,
  ResourceDirective,
  ResourcesDirective,
  Schedule,
  ScheduleComponent,
  TimelineViews,
  ViewDirective,
  ViewsDirective,
  Week,
  Year,
} from "@syncfusion/ej2-react-schedule";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { IAvailabilityDetails } from "../../type/availability";
import { formattedDate } from "../../constants/common";
import moment from "moment";
import { IClientListDetails } from "../../type/client-info";
import {
  IBookingDetails,
  IEmployeePortalData,
} from "../../type/providerDetails";
import { ServiceColor, convertTime12to24 } from "../../type/schedule";
import { batch, useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../redux/store";
import ClientImg from "../../assets/images/images-svg/client-large.svg";
import ProviderImg from "../../assets/images/images-svg/provider-large-vertical.svg";
import { DropDownList } from "@syncfusion/ej2-dropdowns";
import { useNavigate } from "react-router-dom";
import {
  createSession,
  GetSessionById,
} from "../../redux/features/session/sessionSlice";
import { SessionType } from "../../constants/session";
import { GetProviderBookingDetails } from "../../redux/features/providerSlice";
// import BookingEditModal from "./BookingEditModal";

interface IscheduleCalendar {
  selectedProviders: IEmployeePortalData[];
  selectedClients: IClientListDetails[];
}

const ScheduleCalendar: React.FC<IscheduleCalendar> = ({
  selectedProviders,
  selectedClients,
}) => {
  //#region variable region

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  //stores list for calendar
  const [listforCalendar, setlistforCalendar] = useState<any[]>([]);

  //stores clients booking
  const clientbooking = selectedClients.flatMap((item) => item.bookingData);

  //stores  provider booking
  const providerbooking = selectedProviders.flatMap((item) => item.bookingData);

  //stores current provider details
  const providerDetails = useSelector<RootState, IEmployeePortalData>(
    (state) => state.providerSlice.providerProfile
  );

  //stores datasource for schedule calendar
  const [eventData, setEventData] = useState<any[]>([]);

  //handle show bookings
  const [showBookings, setshowBookings] = useState<boolean>(false);

  //#endregion

  //#region schedule calendar region

  //handles view change
  var islayoutChanged = true;

  let schedule: any = new Schedule({
    height: "600px",
    width: "100%",
  });

  //stores schedule component values
  let scheduleObj = useRef<ScheduleComponent>(schedule);

  //handle the prevent availiability overlaping
  const availabilityOverlap = (d1: any, data: IAvailabilityDetails[]) => {
    let overlap: any[] = [];

    data?.forEach((a) => {
      if (
        new Date(d1) <= new Date(formattedDate(a.endDate)) &&
        new Date(d1) >= new Date(formattedDate(a.beginDate))
      ) {
        overlap = [...a.availabilitySelectedDays];
      }
    });

    return overlap;
  };

  //recreate work hours dynamically
  const workHoursDynamic = (
    d1: any,
    selectedClients: any,
    selectedProviders: any
  ) => {
    let results: any[] = [];
    let uniqueIds: string[] = [];
    selectedClients.forEach((item: any) => {
      if (uniqueIds.indexOf(item.clientBasicDetails.id) === -1) {
        uniqueIds.push(item.clientBasicDetails.id);
        results.push(
          availabilityOverlap(
            moment(d1).format("MM/DD/YYYY"),
            item?.availabilityDetails
          )
        );
      }
    });

    let availabilityDetails: any;
    selectedProviders.forEach((item: any) => {
      availabilityDetails = item.availabilityDetails;

      if (uniqueIds.indexOf(item.employmentDetails.id) === -1) {
        uniqueIds.push(item.employmentDetails.id);
        results.push(
          availabilityOverlap(
            moment(d1).format("MM/DD/YYYY"),
            availabilityDetails
          )
        );
      }
    });

    return results;
  };

  //for date Header
  let instance = useMemo(() => {
    return new Internationalization();
  }, []);

  //handles header text in Scheduler
  const getDateHeaderText = useCallback(
    (value: any) => {
      return instance.formatDate(value, { skeleton: "Ed" });
    },
    [instance]
  );

  //handles header template in Scheduler
  const dateHeaderTemplate = (props: any) => {
    return (
      <div>
        <div>{getDateHeaderText(props.date)}</div>
      </div>
    );
  };

  //handles resource name
  function getResourceName(value: ResourceDetails) {
    return (value as ResourceDetails).resourceData[
      (value as any).resource.textField
    ] as string;
  }

  //Customize calendar resource headers
  function resourceHeaderTemplate(props: any): JSX.Element {
    return (
      <div className="template-wrap">
        <div
          className="person-name meetingPersonBox"
          style={{
            background: getColor(props),
          }}
        >
          <img src={getResourceImage(props)} height={30} width={30} alt="" />
          <span>{getResourceName(props)}</span>
        </div>
      </div>
    );
  }

  //handle profile img url
  function getResourceImage(value: ResourceDetails) {
    if (value.resourceData.type === "Client") {
      return value.resourceData?.url || ClientImg;
    } else {
      return value.resourceData?.url || ProviderImg;
    }
  }

  //handle background color for resource cell
  function getColor(value: ResourceDetails): string {
    if (value.resourceData.type === "Client") {
      return "#dafbeb";
    } else {
      return "#f1ecff";
    }
  }

  //handles cell render
  function handleRenderCell(args: RenderCellEventArgs): void {
    if (args.element.classList.contains("e-work-cells")) {
    }
    if (
      args.elementType === "emptyCells" &&
      args.element.classList.contains("e-resource-left-td")
    ) {
      let target: HTMLElement = args.element.querySelector(
        ".e-resource-text"
      ) as HTMLElement;
      target.innerHTML =
        '<div class="name">Person</div><div class="type">List</div>';
    }
  }

  //handles booking service color
  function onEventRendered(args: EventRenderedArgs): void {
    if (args.data.ServiceType) {
      let color = "";
      let border = "";
      let s = args.data.ServiceType;
      if (s === ServiceColor.Assessment) {
        if (args.data.status === "Pending") {
          color = "#E6CCFF";
          border = "2px dotted red";
        } else {
          color = "#E6CCFF";
        }
      } else if (s === ServiceColor.DirectTherapy) {
        if (args.data.status === "Pending") {
          color = "#E1F8DC";
          border = "2px dotted red";
        } else {
          color = "#E1F8DC";
        }
      } else if (s === ServiceColor.ParentTraining) {
        if (args.data.status === "Pending") {
          color = "#F2D1CB";
          border = "2px dotted red";
        } else {
          color = "#F2D1CB";
        }
      } else if (s === ServiceColor.ProtocolModification) {
        if (args.data.status === "Pending") {
          color = "#b7e2fc";
          border = "2px dotted red";
        } else {
          color = "#b7e2fc";
        }
      }
      args.element.style.backgroundColor = color;
      args.element.style.color = "#000";
      args.element.style.border = border;

      if (args.data.status === "Cancelled") {
        args.element.style.textDecorationLine = "line-through";
      }
    }
  }

  //
  const handleDataBinding = useCallback(() => {
    scheduleObj.current.workHours.highlight = false;
    if (islayoutChanged && scheduleObj && scheduleObj.current) {
      //1. get rendered dates in current view
      var renderedDates = scheduleObj.current.activeView.getRenderDates();

      //2. ensure to reset all working hours (Must Have!!)
      // scheduleObj.current.resetWorkHours(
      //   [],
      //   "06:00", //same as calendar default start time
      //   "21:00" //same as calendar default end time;
      // );

      //3. get working hours for each resource
      let workingHoursMaps: any[][] = [
        ...workHoursDynamic(
          renderedDates[0],
          selectedClients,
          selectedProviders
        ),
      ];
      for (var i = 0; i < renderedDates.length; i++) {
        try {
          var dayIndex = renderedDates[i].getDay();

          //4. set working hours for each resource
          for (let j = 0; j < workingHoursMaps.length; j++) {
            if (scheduleObj.current && workingHoursMaps[j][dayIndex]) {
              if (
                workingHoursMaps[j][dayIndex].StartTime === "00:00" &&
                workingHoursMaps[j][dayIndex].EndTime === "00:00"
              ) {
                scheduleObj.current.setWorkHours(
                  [renderedDates[i]],
                  "24:00",
                  "24:00",
                  j //groupIndex = resourceIndex
                );
              } else {
                scheduleObj.current.setWorkHours(
                  [renderedDates[i]],
                  workingHoursMaps[j][dayIndex].StartTime,
                  workingHoursMaps[j][dayIndex].EndTime,
                  j
                );
              }
            } else {
              scheduleObj.current.setWorkHours(
                [renderedDates[i]],
                "24:00",
                "24:00",
                j //groupIndex = resourceIndex
              );
            }
          }
        } catch (e: any) {
          console.log("e:", e);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [islayoutChanged, selectedClients, selectedProviders]);

  //#region methods region

  //populate list data for calendar
  const handlePopulateResources = (
    selectedClients: any[],
    selectedProviders: any[]
  ) => {
    let newList: any[] = [];

    let obj: any;

    //Add Client Into Calendar List
    selectedClients.forEach((selectedClient: any) => {
      obj = {
        name: `${selectedClient?.clientBasicDetails?.childFirstName}${" "}${
          selectedClient?.clientBasicDetails?.childLastName
        }`,
        id: selectedClient?.clientBasicDetails?.id,
        GroupId: 1, //selected provider category
        color: "#865fcf",
        url: selectedClient?.clientBasicDetails?.clientProfile?.url,
        type: "Client",
        hasAuth: selectedClient?.authorizations?.length > 0 ? true : false,
        hasAvailability:
          selectedClient?.availabilityDetails?.length > 0 ? true : false,
      };
      newList.push(obj);
    });

    let availabilityDetails: any;
    selectedProviders.forEach((selectedProvider: any) => {
      obj = {
        name: `${selectedProvider?.employmentDetails?.firstName}${" "}${
          selectedProvider?.employmentDetails?.lastName
        }`,
        id: selectedProvider?.employmentDetails?.id,
        GroupId: 1, //selected provider category
        color: "#865fcf",
        url: selectedProvider?.employmentDetails?.providerProfile?.url,
        type: "Provider",
        hasAvailability: availabilityDetails?.length > 0 ? true : false,
      };
      newList.push(obj);
    });

    return newList;
  };

  //--------------------------------------------------------------
  const filterSchedulesByIds = (
    data: IBookingDetails[],
    clientIds: string[],
    providerIds: string[],
    filterFromDate: Date,
    filterToDate: Date,
    includeCancel: boolean
  ) => {
    let results: IBookingDetails[];

    results = data
      .filter(
        (schedule: IBookingDetails) =>
          (clientIds?.includes(schedule?.bookedClientId?.id) ||
            providerIds?.includes(schedule?.bookedProviderId?.id)) &&
          new Date(schedule?.specificDate).getTime() >=
            filterFromDate.getTime() &&
          new Date(schedule?.specificDate).getTime() <=
            filterToDate.getTime() &&
          (includeCancel ||
            (!includeCancel && schedule?.status.toUpperCase() !== "CANCELLED"))
      )
      .sort(
        (a: any, b: any) =>
          new Date(a.specificDate).getTime() -
          new Date(b.specificDate).getTime()
      );
    return results;
  };

  //--------------------------------------------------------------
  const filteredClientSchedules = useSelector((state: RootState) => {
    const today = new Date();
    const clientsId = selectedClients.map(
      (item) => item?.clientBasicDetails?.id
    );

    return filterSchedulesByIds(
      clientbooking,
      clientsId, //client Id List
      [], //providerId List
      new Date(today.getFullYear() - 1, today.getMonth(), today.getDate()), //[-1 year, + 1 year]
      new Date(today.getFullYear() + 2, today.getMonth(), today.getDate()), //[-1 year, + 1 year]
      true //includeCancel
    );
  });

  //--------------------------------------------------------------
  const filteredProviderSchedules = useSelector(() => {
    const today = new Date();
    const providersId = selectedProviders.map(
      (item: any) => item?.employmentDetails?.id
    );
    return filterSchedulesByIds(
      providerbooking,
      [], //client Id List
      providersId, //providerId List
      new Date(today.getFullYear() - 1, today.getMonth(), today.getDate()), //[-1 year, + 1 year]
      new Date(today.getFullYear() + 2, today.getMonth(), today.getDate()), //[-1 year, + 1 year]
      true //includeCancel
    );
  });

  //populate clients bookings for calendar
  const populateClientBookedData = useCallback(() => {
    let clientResults: any[] = [];
    let bookSchedule: any[] = [];

    bookSchedule = [...filteredClientSchedules];

    populateBookedData(
      "CLIENT",
      //clientData.bookedServices,
      // filteredClientSchedules,
      bookSchedule,
      clientResults
    );

    return clientResults;

    //setClientBooked(clientResults);
  }, [filteredClientSchedules]);

  //handle provider bookings for calendar
  const populateProviderBookedData = useCallback(() => {
    let providerResults: any[] = [];

    let bookSchedule: any[] = [];

    bookSchedule = [...filteredProviderSchedules];

    populateBookedData("PROVIDER", bookSchedule, providerResults);

    return providerResults;
    // setProviderBooked(providerResults);
  }, [filteredProviderSchedules]);

  //populate all bookings
  function populateBookedData(
    clientOrProvider: string,
    bookedSlots: any[],
    results: any[]
  ) {
    let specificDate = new Date();
    let fromTimeStr = "";
    let toTimeStr = "";
    let fromDateTime = new Date();
    let toDateTime = new Date();

    for (let i = 0; i < bookedSlots?.length; i++) {
      specificDate = new Date(
        moment.utc(bookedSlots[i]?.specificDate).format("MM/DD/YYYY")
      );
      fromTimeStr = convertTime12to24(bookedSlots[i]?.fromTime);
      toTimeStr = convertTime12to24(bookedSlots[i]?.toTime);
      const [fromHour, fromMin, fromSec] = fromTimeStr.split(":");
      const [toHour, toMin, toSec] = toTimeStr.split(":");

      fromDateTime = new Date(
        specificDate.getFullYear(),
        specificDate.getMonth(),
        specificDate.getDate(),
        +fromHour,
        +fromMin,
        +fromSec
      );

      toDateTime = new Date(
        specificDate.getFullYear(),
        specificDate.getMonth(),
        specificDate.getDate(),
        +toHour,
        +toMin,
        +toSec
      );

      var bookedBlock = {
        Subject: bookedSlots[i].bookedClientId
          ? `${bookedSlots[i].bookedClientId.childFirstName}${" "}${
              bookedSlots[i].bookedClientId.childLastName
            }${" - "}${bookedSlots[i].bookedProviderId.firstName}${" "}${
              bookedSlots[i].bookedProviderId.lastName
            }`
          : `${bookedSlots[i].bookedProviderId.firstName}${" "}${
              bookedSlots[i].bookedProviderId.lastName
            }`,
        StartTime: fromDateTime.toISOString(),
        EndTime: toDateTime.toISOString(),

        bookedClientId: bookedSlots[i].bookedClientId,
        bookedProviderId: bookedSlots[i].bookedProviderId,

        repeatWorkDays: bookedSlots[i].repeatWorkDays,
        repeatCustomEnd: bookedSlots[i].repeatCustomEnd,
        serviceID: bookedSlots[i].id,
        specificDate: bookedSlots[i].specificDate,
        whoCancelled: bookedSlots[i].whoCancelled,
        cancellationReason: bookedSlots[i].cancellationReason,
        authId: bookedSlots[i].authId,
        authCode: bookedSlots[i].authCode,

        clientId:
          clientOrProvider === "CLIENT"
            ? bookedSlots[i].bookedClientId?.id
            : "",

        Repeat: bookedSlots[i].repeatService,
        ServiceType: bookedSlots[i].serviceType,
        Location: bookedSlots[i].serviceLocation,
        Description: bookedSlots[i].description,
        status: bookedSlots[i].status,
        sessionDetail: bookedSlots[i].sessionDetail,
        isAllDay: false,
        IsBlock: false, //clientOrProvider === "CLIENT" ? true : false,
        id:
          clientOrProvider === "CLIENT"
            ? bookedSlots[i].bookedClientId?.id
            : bookedSlots[i].bookedProviderId?.id,
        GroupId: clientOrProvider === "CLIENT" ? 3 : 4, //booked slots
      };

      results.push(bookedBlock);
    }
  }

  //#endregion

  //#region useEffect region

  useEffect(() => {
    if (selectedClients || selectedProviders) {
      const data = handlePopulateResources(selectedClients, selectedProviders);
      setshowBookings(false);
      setlistforCalendar(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClients, selectedProviders]);

  useEffect(() => {
    setshowBookings(true);
    const clientResults = populateClientBookedData();
    const providerResults = populateProviderBookedData();

    setEventData([...clientResults, ...providerResults]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClients, selectedProviders]);

  //#endregion
  const onPopupOpen = (args: PopupOpenEventArgs) => {
    let data: Record<string, any> = args.data as Record<string, any>
    const isTeamSession = data.id !== providerDetails.employmentDetails.id;
    const popupFooterElement = args.element?.querySelector(".e-popup-footer");
    const isUpcoming =
      new Date(data.specificDate).getTime() >= new Date().getTime();
    if (!popupFooterElement) {
      return;
    }
    if (!data?.sessionDetail && isUpcoming && !isTeamSession) {
      const startSession = document.createElement("button");
      startSession.innerText = "Start Session";
      startSession.className = "e-btn e-css e-primary";
      startSession.style.marginLeft = "120px";
      popupFooterElement?.appendChild(startSession);

      startSession.onclick = () => {
        args.cancel = true;
        dispatch(
          createSession({
            id: data.serviceID,
            sessionType: SessionType.OneOnOne,
          })
        );
        navigate(`/session-details/${data.serviceID}`);
      };
    } else if (!data?.sessionDetail && isUpcoming && isTeamSession) {
      const startSession = document.createElement("button");
      startSession.innerText = "Not Started Yet";
      startSession.className = "e-btn e-css e-primary";
      startSession.style.marginLeft = "120px";
      popupFooterElement?.appendChild(startSession);
    } else if (data.sessionDetail) {
      const viewSession = document.createElement("button");
      viewSession.innerText = "View Session";
      viewSession.className = "e-btn e-css e-primary";
      viewSession.style.marginLeft = "120px";
      popupFooterElement?.appendChild(viewSession);
      viewSession.onclick = () => {
        args.cancel = true;
        dispatch(GetSessionById({ id: data.sessionDetail.id }));
        navigate(`/session-details/${data.sessionDetail.id}`);
      };
    }
  };
  const Scheduler = React.useMemo(
    () => (
      <ScheduleComponent
        cssClass="block-events"
        ref={scheduleObj}
        width="100%"
        height="650px"
        rowAutoHeight={true}
        currentView="Month"
        resourceHeaderTemplate={resourceHeaderTemplate}
        eventSettings={{
          dataSource: [...eventData],
          fields: {
            id: "Id",
            subject: { title: "Summary", name: "Subject" },
            isAllDay: { name: "IsAllDay" },
            location: { title: "Location", name: "Location" },
            endTimezone: { name: "TimeZone" },
            startTimezone: { name: "TimeZone" },
            description: { title: "Description", name: "Description" },
            startTime: { title: "From", name: "StartTime" },
            endTime: { title: "To", name: "EndTime" },
          },
        }}
        eventRendered={onEventRendered.bind(this)}
        renderCell={handleRenderCell.bind(this)}
        dataBinding={handleDataBinding.bind(this)}
        popupOpen={onPopupOpen.bind(this)}
        group={{
          resources: ["CalendarList"],
        }}
        dateHeaderTemplate={dateHeaderTemplate}
      >
        <ResourcesDirective>
          <ResourceDirective
            field="id"
            title="Provider"
            name="CalendarList"
            allowMultiple={true}
            dataSource={listforCalendar}
            textField="name"
            idField="id"
            groupIDField="GroupId"
            colorField="color"
            workDaysField="workDays"
            startHourField="startHour"
            endHourField="endHour"
          ></ResourceDirective>
        </ResourcesDirective>
        <ViewsDirective>
          <ViewDirective
            option="TimelineDay"
            startHour="06:00"
            endHour="21:00"
          />
          <ViewDirective option="Day" startHour="06:00" endHour="21:00" />
          <ViewDirective option="Week" startHour="06:00" endHour="21:00" />
          <ViewDirective option="Month" startHour="06:00" endHour="21:00" />
          <ViewDirective option="Year" startHour="06:00" endHour="21:00" />
          <ViewDirective option="Agenda" startHour="06:00" endHour="21:00" />
        </ViewsDirective>
        <Inject
          services={[
            Day,
            Week,
            Month,
            Year,
            TimelineViews, //day & week
            Resize,
            DragAndDrop,
            Agenda,
          ]}
        />
      </ScheduleComponent>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [eventData, listforCalendar]
  );

  //#endregion

  return (
    <div>
      {showBookings && (
        <div style={{ marginTop: "20px" }} className="control-section">
          {Scheduler}
        </div>
      )}
    </div>
  );
};

export default ScheduleCalendar;
