import React, { useEffect, useState } from "react";
import BorderColorSharpIcon from "@mui/icons-material/BorderColorSharp";
import Close from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Dialog,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import ReactTooltip from "react-tooltip";
import CalendarHeatmap from "react-calendar-heatmap";
import moment from "moment";
import "./schedule.scss";
import { useSelector } from "react-redux";
import {  RootState, useAppDispatch,  } from "../../redux/store";
import { IEmployeePortalData } from "../../type/providerDetails";
import {
  IAvailabilityDates,
  IAvailabilityDetails,
  ISelectedAvailability,
} from "../../type/availability";
import { formattedDate } from "../../constants/common";
import {
  setAvailabilityDetails,
  setInitialState,
  updateProviderAvailabilityById,
} from "../../redux/features/providerSlice";
import AvailabilityForm from "../form/AvailabilityForm";
import { sortDataArray } from "../../utils/AvailabilityUtils";
import { defaultProviderAvailabilityDetail } from "../../constants/availability";
import { setSelectedAvailabiltyValues } from "../../redux/features/availability/availability-slice";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface IScheduleHeatmap {
  isEdit: boolean;
  providerDetails: IEmployeePortalData;
}
const ScheduleHeatmap: React.FC<IScheduleHeatmap> = ({
  isEdit,
  providerDetails,
}) => {
  const dispatch = useAppDispatch();

  //Availability details object to manage the redux
  const availabilityDetails = useSelector<RootState, IAvailabilityDetails[]>(
    (state) => state.providerSlice.providerProfile.availabilityDetails
  );
  
  //stores selected availability
  const availabiltyValues = useSelector<RootState, ISelectedAvailability>(
    (state) => state.availabilitySlice
  );

  //store selected availability
  const [selectedAvailability, setSelectedAvailability] =
    useState<IAvailabilityDetails>(defaultProviderAvailabilityDetail);


  //handles the popup for editing availability details
  const [editAvailabilityDetails, setEditAvailabilityDetails] =
    useState<boolean>(false);

  //handles the data setting in redux for client availability details
  const handleEditAvailabilityClick = () => {
    if (providerDetails.availabilityDetails.length) {
      dispatch(setAvailabilityDetails(providerDetails?.availabilityDetails));
    } else {
      // dispatch(addNewAvailability());
    }
    setEditAvailabilityDetails(true);
  };

  //availability details update handler
  const onSubmitAvailabilityInfo = () => {
    dispatch(updateProviderAvailabilityById());
    setEditAvailabilityDetails(false);
  };

  //used to ser availability
  const handleChangeAvailability = (value: string) => {
    const availability = availabilityDetails.filter(
      (item: any) => item.id === value
    );

    dispatch(
      setSelectedAvailabiltyValues({
        name: "availability",
        value: availability[0],
      })
    );
    setSelectedAvailability(availability[0]);
    // setIsDataEdited(true);
    return availability[0];
  };

  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);


  //set default selection for dropdown
  useEffect(() => {
    console.log("setting drop down selection...");
    if (availabilityDetails?.length > 0) {
      //find availability table based on today
      const defaultDate = new Date();
      const availabilityForToday = availabilityDetails.find(
        (availability: any) =>
          new Date() >=
            (availability.beginDate
              ? new Date(availability.beginDate)
              : defaultDate) &&
          new Date() <=
            (availability.endDate
              ? new Date(availability.endDate)
              : defaultDate)
      );

      if (availabilityForToday) {
        dispatch(
          setSelectedAvailabiltyValues({
            name: "availability",
            value: availabilityForToday,
          })
        );
        setSelectedAvailability(availabilityForToday);
      } else {
        const defaultSelectedAvailablility = sortDataArray<IAvailabilityDetails>(
          availabilityDetails
        )[0];

        dispatch(
          setSelectedAvailabiltyValues({
            name: "availability",
            value: defaultSelectedAvailablility,
          })
        );
        setSelectedAvailability(defaultSelectedAvailablility);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availabilityDetails]);

  return (
    <Grid item xs={12}>
      <div className="detailsCard">
        <div className="detailsCard__header">
          <Typography className="detailsCard__title">Availability</Typography>
          {isEdit && (
            <IconButton
              className="detailsCard__headBtn"
              size="small"
              edge="start"
              onClick={() => handleEditAvailabilityClick()}
            >
              {/* <Edit /> */}

              <BorderColorSharpIcon />
            </IconButton>
          )}
        </div>
        <div className="detailsCard__body">
          <div>
            <Grid container spacing={3} alignItems="top">
              <Grid item xs={12} lg={4} xl={3}>
                <div className="fromGroup matchScheduleBox-time max-w-250px mb-6">
                  <Select
                    className="form-control"
                    value={availabiltyValues.availability?.id}
                    onChange={(e: any) => {
                      handleChangeAvailability(e.target.value);
                    }}
                    MenuProps={MenuProps}
                  >
                    {sortDataArray(providerDetails?.availabilityDetails)?.map(
                      (item: any, index: number) => {
                        return (
                          <MenuItem key={index} value={item.id}>
                            {item?.beginDate
                              ? moment(item?.beginDate).format("MM/DD/YYYY")
                              : ""}
                            {" - "}
                            {item?.endDate
                              ? moment(item?.endDate).format("MM/DD/YYYY")
                              : ""}
                          </MenuItem>
                        );
                      }
                    )}
                  </Select>
                </div>
                <div className="availabilityStatus">
                  <span className="unAvailableBox">Unavailable</span>
                  <span className="availableBox">Available</span>
                  <span className="partiallyBookBox">Partially Booked</span>
                  <span className="fullyBookBox">Fully Booked</span>
                </div>
              </Grid>
              <Grid item xs={12} lg={8} xl={9}>
                <CalendarHeatmap
                  showOutOfRangeDays={true}
                  startDate={moment(
                    availabiltyValues.availability?.beginDate
                  ).format("MM/DD/YYYY")}
                  endDate={moment(availabiltyValues.availability?.beginDate)
                    .add(6, "M")
                    .format("MM/DD/YYYY")}
                  values={
                    providerDetails?.availabilityDetails.length
                      ? availabiltyValues.availability?.heatMap
                      : []
                  }
                  showWeekdayLabels
                  showMonthLabels
                  weekdayLabels={[
                    "Sun",
                    "Mon",
                    "Tue",
                    "Wed",
                    "Thu",
                    "Fri",
                    "Sat",
                  ]}
                  gutterSize={4}
                  tooltipDataAttrs={(value: IAvailabilityDates) => {
                    if (value.date) {
                      return {
                        "data-tip": moment(`${value.date}`).format(
                          "ddd YYYY-MM-DD"
                        ),
                      };
                    }
                    return;
                  }}
                  classForValue={(value) => {
                    if (!value) {
                      return "color-empty";
                    }
                    if (value?.pending) {
                      return `color-pending color-square-${value.count}`;
                    } else {
                      return `color-square-${value.count}`;
                    }
                  }}
                />

                <ReactTooltip />
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
      {editAvailabilityDetails && (
        <Dialog open={editAvailabilityDetails} className="largeModel">
          <Box className="largeModel__head">
            <Typography variant="h5" className="largeModel__title">
              Edit Availability
            </Typography>
            <Button
              className="largeModel__closeBtn"
              onClick={() => {
                dispatch(setInitialState(null));
                setEditAvailabilityDetails(false);
              }}
            >
              <Close />
            </Button>
          </Box>

          <AvailabilityForm
            // isBoardPage={false}
            // isNew={false}
            updateHandler={onSubmitAvailabilityInfo}
          />
        </Dialog>
      )}
    </Grid>
  );
};

export default ScheduleHeatmap;
